import { difference, isNil, round } from 'lodash-es';
import { ExtendedProductionType, ProductionStatistic } from './production';
import { formatNumberAsMonth } from '../../utils/format';
import { Province } from '@pn/core/utils/provinces';

// data from the API calculated from the PN database
export type MonthlyLicensedProduction = {
  id: string | number;
  companyId: string; // links to a company id
  month: number;
  year: number;
  provinces: Province[];
} & ProductionStatistic;

export type MonthlyLicensedProductionView = MonthlyLicensedProduction &
  ExtendedProductionType & {
    readonly yearAndMonth: number;
    readonly formattedYearAndMonth: string;
    readonly missingProvinces: Province[];
  };

export type QuarterlyLicensedProductionView = Omit<
  MonthlyLicensedProduction,
  'month'
> &
  ExtendedProductionType & {
    readonly yearAndQuarter: number;
    readonly formattedYearAndQuarter: string;
  };

// hardcoded check for provinces we want to ensure exist
const provincesToInclude: Province[] = ['AB', 'BC', 'SK', 'MB'];

export function generateMonthlyLicensedProductionView(
  stat: MonthlyLicensedProduction
): MonthlyLicensedProductionView {
  let missingProvinces = difference(provincesToInclude, stat.provinces);
  // missingProvinces = []; // TODO: remove this once we have proper province tracking

  const naturalGasMCF = isNil(stat.naturalGasMMCF)
    ? null
    : stat.naturalGasMMCF * 1000;

  const naturalGasBOEPerDay = isNil(naturalGasMCF)
    ? null
    : round(naturalGasMCF / 6);

  const nglAndCnd =
    isNil(stat.naturalGasLiquidsBBLPerDay) && isNil(stat.condensateBBLD)
      ? null
      : (stat.naturalGasLiquidsBBLPerDay ?? 0) + (stat.condensateBBLD ?? 0);

  const netProductionBOEPerDay =
    (stat.oilBBLD ?? 0) + (naturalGasBOEPerDay ?? 0) + (nglAndCnd ?? 0);

  const percentLiquidProduction =
    isNil(stat.liquidProductionsBBLD) || isNil(netProductionBOEPerDay)
      ? null
      : round((stat.liquidProductionsBBLD / netProductionBOEPerDay) * 100);

  return {
    ...stat,
    formattedYearAndMonth: `${formatNumberAsMonth(stat.month, 'short')}-${
      stat.year
    }`,
    yearAndMonth: parseInt(`${stat.year}${stat.month}`),
    netProductionBOEPerDay,
    naturalGasMCF,
    naturalGasBOEPerDay,
    nglAndCnd,
    missingProvinces,
    percentLiquidProduction,
  };
}

export function convertToQuarterlyLicensedProductionView(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  monthlyStats: any[]
): QuarterlyLicensedProductionView[] {
  const quarterlyData: QuarterlyLicensedProductionView[] = [];

  // Group monthly data by year and quarter
  const groupedData = monthlyStats.reduce((result, data) => {
    const { year, month } = data;
    const quarter = Math.ceil(month / 3); // Calculate the quarter based on the month

    const key = `${year}-${quarter}`; // Create a unique key for the quarter
    if (!result[key as keyof typeof result]) {
      // Initialize the quarterly data if it doesn't exist
      result[key] = {
        year,
        quarter,
        yearAndQuarter: parseInt(`${year}${quarter}`),
        formattedYearAndQuarter: `${year}-Q${quarter}`,
      };
    }

    // Merge the monthly data into the quarterly data
    Object.entries(data).forEach(([field, value]) => {
      if (field !== 'year' && field !== 'month') {
        result[key][field] = (result[key][field] || 0) + (value || 0);
      }
    });

    return result;
  }, {});

  // Convert the grouped data into an array of quarterly data
  Object.values(groupedData).forEach((data) => {
    quarterlyData.push(data as QuarterlyLicensedProductionView);
  });

  return quarterlyData;
}
