import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isEmpty, isNil } from 'lodash-es';
import { IntelReportsState, reportsSlice } from './reportsSlice.ts';
import type { IReportsActions, IReportsStorage } from './ports';
import { createSelector } from '@reduxjs/toolkit';
import { IntelActivityItem } from '@pn/core/domain/intel/intel-activity-item.ts';

export const useReduxReportsStorage = (): IReportsStorage => {
  const { name, getInitialState } = reportsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isReportActive: useSliceSelector(
      (state) => !isEmpty(state.selectedReportId)
    ),
    isEditingReport: useSliceSelector((state) => state.isEditingReport),
    reports: useSliceSelector((state) => state.reports),
    selectedReport: useSliceSelector(selectSelectedReport),
    selectedReportId: useSliceSelector((state) => state.selectedReportId),
    licences: useSliceSelector(selectLicenses),
    spuds: useSliceSelector(selectSpuds),
  };
};

export const reduxReportsActions = (): IReportsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = reportsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    toggleIsEditingReport: () => {
      dispatch(actions.toggleIsEditingReport());
    },
    create: (params) => {
      dispatch(actions.create(params));
    },
    clearSelectedReport: () => {
      dispatch(actions.clearSelectedReport());
    },
    setProduction(production) {
      dispatch(actions.setProduction(production));
    },
    setMonthlyProduction: (monthlyProduction) => {
      dispatch(actions.setMonthlyProduction(monthlyProduction));
    },
    setYearOverYearData: (spudsYearOverYear, licencesYearOverYear) => {
      dispatch(
        actions.setYearOverYearData({
          spudsYearOverYear,
          licencesYearOverYear,
        })
      );
    },
    setMonthlyLicences(monthlyLicences: IntelActivityItem[]) {
      dispatch(actions.setMonthlyLicences(monthlyLicences));
    },
    setMonthlySpuddedLicenses: (monthlySpuddedLicenses) => {
      dispatch(actions.setMonthlySpuddedLicenses(monthlySpuddedLicenses));
    },
    setIsLoadingProduction: () => {
      dispatch(actions.setIsLoadingProduction());
    },
    setIsLoadingWells() {
      dispatch(actions.setIsLoadingWells());
    },
  };
};

const selectSelectedReport = createSelector(
  [
    (state: IntelReportsState) => state.reports,
    (state: IntelReportsState) => state.selectedReportId,
  ],
  (reports, selectedReportId) =>
    reports.find((c) => selectedReportId.includes(c.id))
);

const selectLicenses = createSelector(
  [
    (state: IntelReportsState) => state.reports,
    (state: IntelReportsState) => state.selectedReportId,
  ],
  (reports, selectedReportId) => {
    const report = reports.find((c) => selectedReportId.includes(c.id));
    if (isNil(report)) return [];
    return (report.monthlySpuddedLicenses ?? []).filter(
      (c) => c['licence_date'] === 'licence'
    );
  }
);

const selectSpuds = createSelector(
  [
    (state: IntelReportsState) => state.reports,
    (state: IntelReportsState) => state.selectedReportId,
  ],
  (reports, selectedReportId) => {
    const report = reports.find((c) => selectedReportId.includes(c.id));
    if (isNil(report)) return [];
    return report.monthlyLicenses ?? [];
  }
);
