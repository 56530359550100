import { DataMultiSelectionReason } from '@pn/core/domain/query';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import { workspaceSlice } from '@pn/core/storage/workspace/workspaceSlice';
import { apiAnnotationMapper } from '@pn/services/api/annotation/apiAnnotationMapper';
import { apiLayerItemMapper } from '@pn/services/api/layer/apiLayerMapper';
import { apiListMapper } from '@pn/services/api/list/apiListMapper';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { isNil } from 'lodash-es';
import type { ReducerSync } from '.';

const { actions } = workspaceSlice;

export const workspace: ReducerSync<typeof actions> = {
  update: async (item, dispatch) => {
    const userId = getCurrentUserId(); // HACK
    const isGlobalAndUneditable =
      item.isGlobal && (isNil(item.createdBy) || item.createdBy.id !== userId);

    if (item.isTemporary || isGlobalAndUneditable)
      return new Promise((resolve) => resolve(undefined));

    switch (item.itemType) {
      case 'layer':
        return pnApiClient.request({
          method: 'PUT',
          url: `v2/layers/${item.id}`,
          payload: apiLayerItemMapper().toOriginalItem(item),
        });
      case 'list': {
        const { found, missing } = await pnApiClient.request<{
          found: string[];
          missing: string[];
        }>({
          method: 'PUT',
          url: `v2/lists/${item.id}`,
          payload: apiListMapper().toOriginalItem(item),
        });

        dispatch(
          actions.updateNumberOfElements({
            itemId: item.id,
            numberOfElements: found.length,
          })
        );
        dispatch(
          actions.updateRequestedIds({
            itemId: item.id,
            ids: found,
            reason: DataMultiSelectionReason.List,
          })
        );
        dispatch(actions.updateInvalidDataItemIds(missing));

        break;
      }
      case 'annotation':
      case 'drawing':
        return pnApiClient.request({
          method: 'PUT',
          url: `v2/annotations/${item.id}`,
          payload: apiAnnotationMapper().toOriginalItem(item),
        });
      default:
        throw new Error(`Unknown workspace item type: ${item.itemType}`);
    }
  },
  remove: (item) => {
    if (item.isTemporary) return new Promise((resolve) => resolve(undefined));

    switch (item.itemType) {
      case 'layer':
        return pnApiClient.request({
          method: 'DELETE',
          url: `v2/layers/${item.id}`,
        });
      case 'list':
        return pnApiClient.request({
          method: 'DELETE',
          url: `v2/lists/${item.id}`,
        });
      case 'annotation':
      case 'drawing':
        return pnApiClient.request({
          method: 'DELETE',
          url: `v2/annotations/${item.id}`,
        });
      default:
        throw new Error(`Unknown workspace item type: ${item.itemType}`);
    }
  },
};
