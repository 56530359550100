import {
  isPolyClosed,
  type CanvasFeature,
  type CircleFeature,
  type PathFeature,
  type PolyFeature,
  type TextFeature,
  type Transformation,
} from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import type { DrawingState } from '@pn/services/drawing';
import {
  RESIZE_HANDLE_SIZE,
  SELECT_STROKE_WIDTH,
  computeCanvasTransformation,
  drawBoxSelection,
  drawResizeHandle,
  drawVertexHandle,
  formatArea,
  formatDistance,
  getCanvasBoundingBox,
} from '@pn/services/drawing';
import { isTransparent } from '@pn/services/utils/color';
import assert from 'assert';
import { isNil } from 'lodash-es';

export function getContext(
  canvas: HTMLCanvasElement | null | undefined
): CanvasRenderingContext2D {
  const ctx = canvas?.getContext('2d');
  assert(ctx, 'Canvas context not found');
  return ctx;
}

export function drawStatic(params: {
  ctx: CanvasRenderingContext2D;
  drawingState: DrawingState;
  transformation: Transformation;
}): void {
  const { ctx, drawingState, transformation } = params;

  ctx.save();

  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  ctx.scale(transformation.scale, transformation.scale);
  ctx.translate(transformation.dx, transformation.dy);

  for (const id of drawingState.order) {
    const feature = drawingState.features[id];
    if (!feature.isVisible) continue;
    drawingState.paths[id] = drawFeature(ctx, feature);
  }

  ctx.restore();
}

export function drawLive(params: {
  ctx: CanvasRenderingContext2D;
  drawingState: DrawingState;
  transformation: Transformation;
}): void {
  const { ctx, drawingState, transformation } = params;

  const boxSelection = drawingState.boxSelection;
  const featuresSelectedArr = Object.values(drawingState.featuresSelected);
  const isSingleSelection = featuresSelectedArr.length === 1;

  ctx.save();

  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  ctx.scale(transformation.scale, transformation.scale);
  ctx.translate(transformation.dx, transformation.dy);

  if (!isNil(boxSelection))
    drawBoxSelection({ ctx, bbox: boxSelection, transformation });

  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  featuresSelectedArr.forEach((feature) => {
    const bbox = getCanvasBoundingBox(feature);

    const adjustedX = bbox.x - bbox.strokeWidth / 2;
    const adjustedY = bbox.y - bbox.strokeWidth / 2;
    const adjustedWidth = bbox.width + bbox.strokeWidth;
    const adjustedHeight = bbox.height + bbox.strokeWidth;

    minX = Math.min(minX, adjustedX);
    minY = Math.min(minY, adjustedY);
    maxX = Math.max(maxX, adjustedX + adjustedWidth);
    maxY = Math.max(maxY, adjustedY + adjustedHeight);

    if (isSingleSelection) return;

    /**
     * Thin outline around every multi-selected feature.
     * Not drawn in single selection mode.
     */
    ctx.lineWidth = 1 / transformation.scale;
    const dashOffset = 6 / transformation.scale;
    ctx.setLineDash([dashOffset, dashOffset]);

    ctx.strokeStyle = 'black';
    ctx.strokeRect(
      bbox.x - bbox.strokeWidth / 2,
      bbox.y - bbox.strokeWidth / 2,
      bbox.width + bbox.strokeWidth,
      bbox.height + bbox.strokeWidth
    );

    ctx.lineDashOffset = dashOffset; // offset to align white dashes with gaps
    ctx.strokeStyle = 'white';
    ctx.strokeRect(
      bbox.x - bbox.strokeWidth / 2,
      bbox.y - bbox.strokeWidth / 2,
      bbox.width + bbox.strokeWidth,
      bbox.height + bbox.strokeWidth
    );

    ctx.setLineDash([]);
    ctx.lineDashOffset = 0;
  });

  const containerBbox = {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
    strokeWidth: 2 / transformation.scale,
  };

  if (isFinite(containerBbox.x)) {
    ctx.lineWidth = SELECT_STROKE_WIDTH / transformation.scale;
    const dashOffset = 6 / transformation.scale;
    ctx.setLineDash([dashOffset, dashOffset]);

    ctx.strokeStyle = 'black';
    ctx.strokeRect(
      containerBbox.x - containerBbox.strokeWidth / 2,
      containerBbox.y - containerBbox.strokeWidth / 2,
      containerBbox.width + containerBbox.strokeWidth,
      containerBbox.height + containerBbox.strokeWidth
    );

    ctx.lineDashOffset = dashOffset; // offset to align white dashes with gaps
    ctx.strokeStyle = 'white';
    ctx.strokeRect(
      containerBbox.x - containerBbox.strokeWidth / 2,
      containerBbox.y - containerBbox.strokeWidth / 2,
      containerBbox.width + containerBbox.strokeWidth,
      containerBbox.height + containerBbox.strokeWidth
    );

    ctx.setLineDash([]);
    ctx.lineDashOffset = 0;

    const offset =
      containerBbox.strokeWidth / 2 + RESIZE_HANDLE_SIZE / transformation.scale;

    const xMin = containerBbox.x - offset;
    const xMax = containerBbox.x + containerBbox.width + offset;
    const yMin = containerBbox.y - offset;
    const yMax = containerBbox.y + containerBbox.height + offset;

    drawResizeHandle({ ctx, x: xMin, y: yMin, transformation });
    drawResizeHandle({ ctx, x: xMax, y: yMin, transformation });
    drawResizeHandle({ ctx, x: xMin, y: yMax, transformation });
    drawResizeHandle({ ctx, x: xMax, y: yMax, transformation });
  }

  const featureSelected = isSingleSelection
    ? featuresSelectedArr[0]
    : undefined;
  if (featureSelected?.type === 'poly' && isNil(featureSelected.subType)) {
    featureSelected.coordinates.forEach((point) =>
      drawVertexHandle({ ctx, x: point.x, y: point.y, transformation })
    );
  }

  ctx.restore();
}

export function drawFeature(
  ctx: CanvasRenderingContext2D,
  feature: CanvasFeature
): Path2D {
  switch (feature.type) {
    case 'path':
      return drawPath(ctx, feature);
    case 'poly':
      return drawPoly(ctx, feature);
    case 'circle':
      return drawCircle(ctx, feature);
    case 'text':
      return drawText(ctx, feature);
  }
}

function drawPath(ctx: CanvasRenderingContext2D, feature: PathFeature): Path2D {
  const path = new Path2D(feature.pathData);
  ctx.strokeStyle = feature.strokeColor;
  ctx.lineWidth = feature.strokeWidth;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.globalAlpha = feature.opacity;
  ctx.stroke(path);

  ctx.globalAlpha = 1;

  return path;
}

const workCanvas = document.createElement('canvas');
const workCtx = getContext(workCanvas);

function hasSharpEdges(feature: PolyFeature): boolean {
  return ['rectangle', 'rectangle_selection'].includes(feature.subType ?? '');
}

function drawPoly(ctx: CanvasRenderingContext2D, feature: PolyFeature): Path2D {
  const path = new Path2D();
  feature.coordinates.forEach((pt, index) =>
    index === 0 ? path.moveTo(pt.x, pt.y) : path.lineTo(pt.x, pt.y)
  );
  if (isPolyClosed(feature)) path.closePath();

  /**
   * Use off-screen canvas to fill the polygon with its stroke overlayed and
   * apply the result to the main canvas.
   */
  workCanvas.width = ctx.canvas.width;
  workCanvas.height = ctx.canvas.height;

  const { scale, dx, dy } = computeCanvasTransformation(ctx);

  workCtx.save();

  workCtx.scale(scale, scale);
  workCtx.translate(dx, dy);

  if (!isTransparent(feature.fillColor) && isPolyClosed(feature)) {
    workCtx.fillStyle = feature.fillColor;
    workCtx.fill(path);
  }

  if (feature.strokeWidth > 0) {
    workCtx.strokeStyle = feature.strokeColor;
    workCtx.lineWidth = feature.strokeWidth;
    workCtx.lineCap = hasSharpEdges(feature) ? 'butt' : 'round';
    workCtx.lineJoin = hasSharpEdges(feature) ? 'miter' : 'round';
    workCtx.stroke(path);
  }

  workCtx.restore();

  ctx.globalAlpha = feature.opacity;

  ctx.drawImage(
    workCanvas,
    -dx,
    -dy,
    ctx.canvas.width / scale,
    ctx.canvas.height / scale
  );

  const length = feature.coordinates.length;

  if (feature.arrow && length > 1) {
    const start = feature.coordinates[length - 2];
    const end = feature.coordinates[length - 1];

    const angle = Math.atan2(end.y - start.y, end.x - start.x);
    const arrowLength = Math.min(
      feature.strokeWidth * 4,
      Math.sqrt((end.y - start.y) ** 2 + (end.x - start.x) ** 2) * 0.33
    );
    const arrowAngle = Math.PI / 6;

    const arrow1 = {
      x: end.x - arrowLength * Math.cos(angle - arrowAngle),
      y: end.y - arrowLength * Math.sin(angle - arrowAngle),
    };
    const arrow2 = {
      x: end.x - arrowLength * Math.cos(angle + arrowAngle),
      y: end.y - arrowLength * Math.sin(angle + arrowAngle),
    };

    ctx.beginPath();
    ctx.moveTo(end.x, end.y);
    ctx.lineTo(arrow1.x, arrow1.y);
    ctx.moveTo(end.x, end.y);
    ctx.lineTo(arrow2.x, arrow2.y);
    ctx.stroke();
  }

  if (!isNil(feature.measurements)) {
    const { metric, imperial } = isNil(feature.measurements.area)
      ? formatDistance(feature.measurements.distance)
      : formatArea(feature.measurements.area);

    drawMeasurementBox({
      ctx,
      text: metric + '\n' + imperial,
      position: feature.measurements.position,
      fontSize: feature.measurements.fontSize,
    });
  }

  ctx.globalAlpha = 1;

  return path;
}

function drawCircle(
  ctx: CanvasRenderingContext2D,
  feature: CircleFeature
): Path2D {
  const path = new Path2D();
  path.arc(feature.center.x, feature.center.y, feature.radius, 0, 2 * Math.PI);

  /**
   * Use off-screen canvas to fill the circle with its stroke overlayed and
   * apply the result to the main canvas.
   */
  workCanvas.width = ctx.canvas.width;
  workCanvas.height = ctx.canvas.height;

  const { scale, dx, dy } = computeCanvasTransformation(ctx);

  workCtx.save();

  workCtx.scale(scale, scale);
  workCtx.translate(dx, dy);

  if (!isTransparent(feature.fillColor)) {
    workCtx.fillStyle = feature.fillColor;
    workCtx.fill(path);
  }

  if (feature.strokeWidth > 0) {
    workCtx.strokeStyle = feature.strokeColor;
    workCtx.lineWidth = feature.strokeWidth;
    workCtx.stroke(path);
  }

  workCtx.restore();

  ctx.globalAlpha = feature.opacity;

  ctx.drawImage(
    workCanvas,
    -dx,
    -dy,
    ctx.canvas.width / scale,
    ctx.canvas.height / scale
  );

  if (!isNil(feature.measurements)) {
    const { metric, imperial } = formatDistance(feature.measurements.radius);

    drawMeasurementBox({
      ctx,
      text: metric + '\n' + imperial,
      position: feature.measurements.position,
      fontSize: feature.measurements.fontSize,
    });
  }

  ctx.globalAlpha = 1;

  return path;
}

function drawText(ctx: CanvasRenderingContext2D, feature: TextFeature): Path2D {
  const x = feature.position.x;
  const initialY = feature.position.y + feature.fontSize * (1 - 0.05);

  ctx.font = `${feature.fontSize}px ${feature.fontFamily}`;
  ctx.textAlign = 'start';
  ctx.fillStyle = feature.textColor;
  ctx.globalAlpha = feature.opacity;

  const lines = feature.text.split('\n');
  const lineHeight = feature.fontSize * 1.2;

  lines.forEach((line, index) => {
    const y = initialY + index * lineHeight;
    ctx.fillText(line, x, y);
  });

  ctx.globalAlpha = 1;

  /* Create a Path2D object with the text bounding box */
  const path = new Path2D();

  const textMetrics = lines.map((line) => ctx.measureText(line));
  const first = textMetrics[0];
  const last = textMetrics[textMetrics.length - 1];
  const textHeight =
    (lines.length - 1) * lineHeight +
    first.actualBoundingBoxAscent +
    last.actualBoundingBoxDescent;
  const textWidth = Math.max(...textMetrics.map((metrics) => metrics.width));

  path.rect(x, initialY - first.actualBoundingBoxAscent, textWidth, textHeight);

  return path;
}

function drawMeasurementBox(params: {
  ctx: CanvasRenderingContext2D;
  text: string;
  position: Point;
  fontSize: number;
}): void {
  const { ctx, text, position, fontSize } = params;

  ctx.font = `${fontSize}px Roboto`;
  ctx.textAlign = 'center';

  const lines = text.split('\n');
  const lineHeight = fontSize * 1.2;

  const textMetrics = lines.map((line) => ctx.measureText(line));
  const textHeight = lines.length * lineHeight;
  const textWidth = Math.max(...textMetrics.map((metrics) => metrics.width));

  const padding = fontSize * 0.5;
  const radius = fontSize * 0.5;
  const yOffset = lines.length * lineHeight * 1;
  const rectX = position.x - textWidth / 2 - padding;
  const rectY = position.y - yOffset - textHeight / 2 - padding / 2;
  const rectWidth = textWidth + 2 * padding;
  const rectHeight = textHeight + padding;

  ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
  ctx.beginPath();
  ctx.moveTo(rectX + radius, rectY);
  ctx.lineTo(rectX + rectWidth - radius, rectY);
  ctx.quadraticCurveTo(
    rectX + rectWidth,
    rectY,
    rectX + rectWidth,
    rectY + radius
  );
  ctx.lineTo(rectX + rectWidth, rectY + rectHeight - radius);
  ctx.quadraticCurveTo(
    rectX + rectWidth,
    rectY + rectHeight,
    rectX + rectWidth - radius,
    rectY + rectHeight
  );
  ctx.lineTo(rectX + radius, rectY + rectHeight);
  ctx.quadraticCurveTo(
    rectX,
    rectY + rectHeight,
    rectX,
    rectY + rectHeight - radius
  );
  ctx.lineTo(rectX, rectY + radius);
  ctx.quadraticCurveTo(rectX, rectY, rectX + radius, rectY);
  ctx.closePath();
  ctx.fill();

  ctx.fillStyle = 'black';

  const initialY = position.y - yOffset - fontSize * 0.25;
  lines.forEach((line, index) => {
    const y = initialY + index * lineHeight;
    ctx.fillText(line, position.x, y);
  });
}
