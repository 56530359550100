import React from 'react';
import { useIntelQueriesStorage } from 'src/storage';
import { isNil } from 'lodash-es';

export type BottomTableTabType = {
  id: string;
  type: 'intelQuery' | 'report' | 'new';
};

export const BottomTableStateContext = React.createContext<{
  tabs: BottomTableTabType[];
  selectedTab: BottomTableTabType | undefined;
  maxTabsReached: boolean;
  handleSelectIntelQueryTab: (id: string) => void;
  handleSelectReportTab: () => void;
  handleCloseCurrentTab: () => void;
  handleCreateNewTab: () => void;
}>({
  tabs: [],
  selectedTab: undefined,
  maxTabsReached: false,
  handleSelectIntelQueryTab: () => {},
  handleSelectReportTab: () => {},
  handleCloseCurrentTab: () => {},
  handleCreateNewTab: () => {},
});

export const BottomTableStateProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { queries } = useIntelQueriesStorage();
  const [selectedTabId, setSelectedTabId] = React.useState<string | undefined>(
    undefined
  );
  const [selectedTabType, setSelectedTabType] = React.useState<
    'intelQuery' | 'report' | 'new' | undefined
  >(undefined);

  const tabs: BottomTableTabType[] = React.useMemo(
    () =>
      queries
        .filter((query) => isNil(query.linkedReportId))
        .map((query) => query.id)
        .map((id) => {
          return { id, type: 'intelQuery' };
        }),
    [queries]
  );

  const selectedTab = React.useMemo(() => {
    return tabs.find(
      (tab) => tab.id === selectedTabId && tab.type === selectedTabType
    );
  }, [tabs, selectedTabId, selectedTabType]);

  const handleSelectIntelQueryTab = React.useCallback((id: string) => {
    setSelectedTabId(id);
    setSelectedTabType('intelQuery');
  }, []);

  const handleSelectReportTab = React.useCallback(() => {
    setSelectedTabId('report');
    setSelectedTabType('report');
  }, []);

  const handleCloseCurrentTab = React.useCallback(() => {
    const indexOfSelectedTab = tabs.findIndex(
      (tab) => tab.id === selectedTabId
    );

    const nextAvailableTab = tabs[indexOfSelectedTab + 1];
    const previousAvailableTab = tabs[indexOfSelectedTab - 1];

    if (nextAvailableTab) {
      setSelectedTabId(nextAvailableTab.id);
      setSelectedTabType(nextAvailableTab.type);
    } else if (previousAvailableTab) {
      setSelectedTabId(previousAvailableTab.id);
      setSelectedTabType(previousAvailableTab.type);
    } else {
      setSelectedTabId(undefined);
    }
  }, [selectedTabId, tabs]);

  const handleCreateNewTab = React.useCallback(() => {
    setSelectedTabId('new');
    setSelectedTabType('new');
  }, []);

  return (
    <BottomTableStateContext.Provider
      value={{
        tabs,
        selectedTab,
        maxTabsReached: tabs.length >= 10,
        handleSelectIntelQueryTab,
        handleCloseCurrentTab,
        handleCreateNewTab,
        handleSelectReportTab,
      }}
    >
      {children}
    </BottomTableStateContext.Provider>
  );
};

export const useBottomTable = () => {
  const context = React.useContext(BottomTableStateContext);
  if (!context) {
    throw new Error(
      'useBottomTable must be used within an BottomTableStateProvider'
    );
  }
  return context;
};
