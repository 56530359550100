import { dependencies } from '@pn/core/dependencies';
import type { User } from '@pn/core/domain/user';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import type { IUserActions, IUserStorage } from './ports';
import { currentUserSlice } from './userSlice';

export const useReduxCurrentUserStorage = (): IUserStorage => {
  const { name, getInitialState } = currentUserSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isError: useSliceSelector((state) => state.isError),
    user: useSliceSelector((state) => state.user),
    isUserLoaded: useSliceSelector((state) => !isNil(state.user)),
    isStackUser: useSliceSelector(
      (state) => !isNil(state.user?.enterprise?.stackCompanyId)
    ),
    stackToken: useSliceSelector((state) => state.stackToken),
    stackCompanyId: useSliceSelector((state) => state.stackCompanyId),
    isAdminMode: useSliceSelector(
      (state) => state.isAdminMode && state.user?.isAdmin === true
    ),
  };
};

export const reduxCurrentUserActions = (): IUserActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = currentUserSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (user) => dispatch(actions.receive(user)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    updateProfile: (userForm) => dispatch(actions.updateProfile(userForm)),
    updateHomeLocation: (homeLocation) =>
      dispatch(actions.updateHomeLocation(homeLocation)),
    updateStackCompanyId: (stackCompanyId) =>
      dispatch(actions.updateStackCompanyId(stackCompanyId)),
    updateStackToken: (stackToken) =>
      dispatch(actions.updateStackToken(stackToken)),
    toggleAdminMode: () => dispatch(actions.toggleAdminMode()),
  };
};

/**
 * You should not be using this function.
 * Only used for error logging outside of React hooks.
 */
export function getCurrentUserId(): User['id'] | undefined {
  const { name } = currentUserSlice;

  return dependencies.store.getState()[name].user?.id;
}

// HACK
export function getCurrentUser(): User | undefined {
  const { name } = currentUserSlice;

  return dependencies.store.getState()[name].user;
}

/**
 * You should not be using this function.
 * Only used for map initialization.
 */
export function getCurrentUserHomeLocation(): User['homeLocation'] {
  const { name } = currentUserSlice;

  return dependencies.store.getState()[name].user?.homeLocation;
}
