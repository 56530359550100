import { createSlice } from '@reduxjs/toolkit';
import { IntelReport } from 'src/domain/intel-report.ts';

export type IntelReportsState = {
  isEditingReport: boolean;
  reports: IntelReport[];
  selectedReportId: string;
};

const initialState: IntelReportsState = {
  isEditingReport: false,
  reports: [],
  selectedReportId: '',
};

export const createReportsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      toggleIsEditingReport: (state) => {
        state.isEditingReport = !state.isEditingReport;
      },
      create: (state, params) => {
        const report = params.payload;
        state.reports.push(report);
        state.selectedReportId = report.id;
        state.isEditingReport = false;
      },
      clearSelectedReport: (state) => {
        state.selectedReportId = '';
        state.reports = state.reports.filter(
          (report) => report.id !== state.selectedReportId
        );
      },
      setProduction: (state, action) => {
        const report = state.reports.find(
          (report) => report.id === state.selectedReportId
        );
        if (!report) return;
        report.production = action.payload;
        report.isLoadingProduction = false;
      },
      setMonthlyProduction: (state, action) => {
        const report = state.reports.find(
          (report) => report.id === state.selectedReportId
        );
        if (!report) return;
        report.monthlyProduction = action.payload;
        report.isLoadingThreeMonthsProduction = false;
      },
      setYearOverYearData: (state, action) => {
        const report = state.reports.find(
          (report) => report.id === state.selectedReportId
        );
        if (!report) return;
        report.spudsYearOverYear = action.payload.spudsYearOverYear;
        report.licencesYearOverYear = action.payload.licencesYearOverYear;
        report.isLoadingYearOverYear = false;
      },
      setMonthlyLicences: (state, action) => {
        const report = state.reports.find(
          (report) => report.id === state.selectedReportId
        );
        if (!report) return;
        report.monthlyLicenses = action.payload;
        report.isLoadingMonthlyLicenses = false;
      },
      setMonthlySpuddedLicenses: (state, action) => {
        const report = state.reports.find(
          (report) => report.id === state.selectedReportId
        );
        if (!report) return;
        report.monthlySpuddedLicenses = action.payload;
        report.isLoadingMonthlySpuddedLicenses = false;
      },
      setIsLoadingProduction: (state) => {
        const report = state.reports.find(
          (report) => report.id === state.selectedReportId
        );
        if (!report) return;
        report.isLoadingProduction = true;
        report.isLoadingThreeMonthsProduction = true;
      },
      setIsLoadingWells: (state) => {
        const report = state.reports.find(
          (report) => report.id === state.selectedReportId
        );
        if (!report) return;
        report.isLoadingMonthlyLicenses = true;
        report.isLoadingYearOverYear = true;
        report.isLoadingMonthlySpuddedLicenses = true;
      },
    },
  });

export const reportsSlice = createReportsSlice('report');
