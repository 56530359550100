import React from 'react';
import { GridDensity } from '@mui/x-data-grid-pro';

type PreferredAreaUnit = 'ha' | 'section';

type ActivityMapType = {
  isMapHidden: boolean;
  isLegendHidden: boolean;
  enableIndividualMapSelections: boolean;
  preferredAreaUnits: PreferredAreaUnit;
  tableDensity: GridDensity;
  handleShow: () => void;
  handleHide: () => void;
  handleToggleMap: () => void;
  handleToggleLegend: () => void;
  handleSetPreferredAreaUnits: (tab: PreferredAreaUnit) => void;
  handleChangeTableDensity: (density: GridDensity) => void;
  handleToggleIndividualMapSelections: () => void;
};

const isMapVisibleOnLoad: boolean =
  !window.location.search.includes('hide_map=true');

export const ActivityMapStateContext = React.createContext<ActivityMapType>({
  isMapHidden: false,
  enableIndividualMapSelections: true,
  isLegendHidden: true,
  preferredAreaUnits: 'section',
  tableDensity: 'standard',
  handleShow: () => {},
  handleHide: () => {},
  handleToggleMap: () => {},
  handleToggleLegend: () => {},
  handleSetPreferredAreaUnits: () => {},
  handleChangeTableDensity: () => {},
  handleToggleIndividualMapSelections: () => {},
});

export const ActivityMapStateProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [tableDensity, setTableDensity] =
    React.useState<GridDensity>('standard');

  const [isMapHidden, setIsMapHidden] = React.useState(!isMapVisibleOnLoad);
  const [isLegendHidden, setIsLegendHidden] = React.useState(false);
  const [enableIndividualMapSelections, setEnableIndividualMapSelections] =
    React.useState(true);

  const [preferredAreaUnits, setPreferredAreaUnits] =
    React.useState<PreferredAreaUnit>('section');

  const handleShow = () => setIsMapHidden(false);
  const handleHide = () => setIsMapHidden(true);
  const handleToggleMap = () => setIsMapHidden((prevState) => !prevState);
  const handleToggleLegend = () => setIsLegendHidden((prevState) => !prevState);
  const handleSetPreferredAreaUnits = (tab: PreferredAreaUnit) =>
    setPreferredAreaUnits(tab);
  const handleToggleIndividualMapSelections = () =>
    setEnableIndividualMapSelections((prevState) => !prevState);

  return (
    <ActivityMapStateContext.Provider
      value={{
        tableDensity,
        isMapHidden,
        enableIndividualMapSelections,
        isLegendHidden,
        preferredAreaUnits,
        handleShow,
        handleHide,
        handleToggleMap,
        handleToggleLegend,
        handleSetPreferredAreaUnits,
        handleChangeTableDensity: setTableDensity,
        handleToggleIndividualMapSelections,
      }}
    >
      {children}
    </ActivityMapStateContext.Provider>
  );
};

export const useActivityMap = (): ActivityMapType => {
  const context = React.useContext(ActivityMapStateContext);
  if (!context) {
    throw new Error(
      'useActivityMap must be used within an ActivityMapStateProvider'
    );
  }
  return context;
};
