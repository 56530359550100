import { LayerType } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  getLayerId,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { isProduction } from '@pn/core/utils/env';

export const grids: WorkspaceItem = {
  folder: 'Petro Ninja',
  isTemporary: false,
  id: 'grids',
  dataType: 'grids',
  itemType: 'layer',
  numberOfElements: undefined,
  createdBy: undefined,
  name: 'Grids',
  description: 'DLS & NTS grid in Canada',
  origin: 'pn',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  isGlobal: true,
  map: {
    layers: [
      {
        id: getLayerId('grids', 'grids', 0),
        name: 'Grids',
        type: LayerType.Polygon,
        style: { color: 'transparent' },
        source: {
          type: 'vector',
          url: 'mapbox://sbilston.grids',
        },
        sourceLayer: 'grids',
        renderAsPoints: true,
      },
    ],
  },
  dataSource: {
    type: 'api',
    source: isProduction() ? 'elastic' : 'parquet',
    url: isProduction() ? 'v2/data' : 'v2/layers/grids',
    requiredMapDataFields: ['internal_id', 'geometry'],
  },
  detailsSource: 'api',
  query: buildQuery({
    id: 'grids',
    dataType: 'grids',
  }),
  module: 'grids',
  ...createInitInternalState({ isVisualized: false }),
};
