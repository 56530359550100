import ReactDOM from 'react-dom/client';
import { App } from 'src/web-ui/App';
import './index.css';

// FUN LITTLE TODO FOR JEREMY: try wrapping the App with React.StrictMode

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);
