import { alpha, colors } from '@mui/material';
import { LayerType } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  getLayerId,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { isProduction } from '@pn/core/utils/env';
import { DEFAULT_COLOR, DEFAULT_POLYGON_OPACITY } from '@pn/services/styles';

export const dispositions: WorkspaceItem = {
  folder: 'Petro Ninja',
  isTemporary: false,
  id: 'dispositions',
  dataType: 'dispositions',
  itemType: 'layer',
  numberOfElements: undefined,
  createdBy: undefined,
  name: 'Surface Dispositions',
  description: '340k dispositions in Canada',
  origin: 'pn',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  isGlobal: true,
  map: {
    layers: [
      {
        id: getLayerId('dispositions', 'dispositions', 0),
        name: 'Dispositions',
        type: LayerType.Polygon,
        style: {
          color: [
            'match',
            ['get', 'disposition_type'],
            'MSL',
            alpha(colors.blue[500], DEFAULT_POLYGON_OPACITY),
            'LANDS',
            alpha(colors.blue[500], DEFAULT_POLYGON_OPACITY),
            'PLA',
            alpha(colors.orange[500], DEFAULT_POLYGON_OPACITY),
            'PIPELINES',
            alpha(colors.orange[500], DEFAULT_POLYGON_OPACITY),
            'LOC',
            alpha(colors.brown[500], DEFAULT_POLYGON_OPACITY),
            'PIL',
            alpha(colors.purple[500], DEFAULT_POLYGON_OPACITY),
            'EZE',
            alpha(colors.green[500], DEFAULT_POLYGON_OPACITY),
            'ROADS',
            alpha(colors.brown[500], DEFAULT_POLYGON_OPACITY),
            alpha(colors.grey[500], DEFAULT_POLYGON_OPACITY),
          ],
        },
        source: {
          type: 'vector',
          url: isProduction()
            ? 'mapbox://sbilston.production-units_pools_disp'
            : 'mapbox://sbilston.staging-units_pools_disp',
        },
        sourceLayer: 'active-disps',
        renderAsPoints: true,
      },
      {
        id: getLayerId('dispositions', 'dispositions', 1),
        name: 'Border 1',
        type: LayerType.Line,
        style: {
          color: DEFAULT_COLOR,
          width: 0.5,
        },
        source: {
          type: 'vector',
          url: isProduction()
            ? 'mapbox://sbilston.production-units_pools_disp'
            : 'mapbox://sbilston.staging-units_pools_disp',
        },
        sourceLayer: 'active-disps',
        renderAsPoints: true,
      },
    ],
  },
  dataSource: {
    type: 'api',
    source: 'parquet',
    url: 'v2/layers/dispositions',
    requiredMapDataFields: ['internal_id', 'geometry', 'disposition_type'],
  },
  detailsSource: 'api',
  query: buildQuery({
    id: 'dispositions',
    dataType: 'dispositions',
  }),
  ...createInitInternalState({ isVisualized: false }),
};
