import {
  reduxFormationsActions,
  useReduxFormationsStorage,
} from './formations/formationsStorage';
import {
  reduxIntelQueriesActions,
  useReduxIntelQueriesStorage,
} from './intel-query/intelQueriesStorage';
import {
  reduxCompanyKPIsActions,
  useReduxCompanyKPIsStorage,
} from './kpi/companyKPIsStorage';
import {
  reduxMergersAndAcquisitionsActions,
  useReduxMergersAndAcquisitionsStorage,
} from './mergers-and-acquisitions/mergersAndAcquisitionsStorage';
import {
  reduxMonthlyStatsActions,
  useReduxMonthlyStatsStorage,
} from './monthly-data/companyMonthlyStatsStorage';
import {
  reduxReportsActions,
  useReduxReportsStorage,
} from './report/reportsStorage.ts';

/**
 * Singular company data section
 */
export const mergersAndAcquisitionsActions = reduxMergersAndAcquisitionsActions;
export const useMergersAndAcquisitionsStorage =
  useReduxMergersAndAcquisitionsStorage;

export const monthlyStatsActions = reduxMonthlyStatsActions;
export const useMonthlyStatsStorage = useReduxMonthlyStatsStorage;
export const companyKPIsActions = reduxCompanyKPIsActions;
export const useCompanyKPIsStorage = useReduxCompanyKPIsStorage;

export const useFormationsStorage = useReduxFormationsStorage;
export const formationsActions = reduxFormationsActions;

export const useIntelQueriesStorage = useReduxIntelQueriesStorage;
export const intelQueriesActions = reduxIntelQueriesActions;

export const useReportsStorage = useReduxReportsStorage;
export const reportsActions = reduxReportsActions;
