import type { Action, Dispatch, Slice } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { adminUsers } from './adminUsers';
import { channels } from './channels';
import { companies } from './companies';
import { companyQuarterlyStats } from './companyQuarterlyStats';
import { currentUser } from './currentUser';
import { enterpriseUsers } from './enterpriseUsers';
import { enterprises } from './enterprises';
import { invitedUsers } from './invitedUsers';
import { projects } from './projects';
import { table } from './table';
import { workspace } from './workspace';
import { intelAlerts } from '@pn/services/redux/api-sync/intelAlerts.ts';

export type ReducerSync<A extends Slice['actions']> = Partial<{
  [key in keyof A]: (
    action: Parameters<A[key]>[0],
    dispatch: Dispatch<Action>
  ) => Promise<void>;
}>;

export const sync: Record<string, any> = {
  currentUser,
  enterpriseUsers,
  invitedUsers,
  adminUsers,
  workspace,
  enterprises,
  projects,
  channels,
  table,
  companyQuarterlyStats,
  companies,
  intelAlerts,
};

export function isSynced(reducer: string, actionType: string): boolean {
  return get(sync, [reducer, actionType], false);
}
