import { MergerAndAquisition } from '@pn/core/domain/intel/intel-data';
import { IApiIntelDataMapper } from '@pn/core/mappers/intel-data';
import { isNil } from 'lodash-es';

export const apiIntelDataMapper: IApiIntelDataMapper = {
  toDomainMergerAndAcquisition: (apiMergerAndAcquisition) => {
    if (isNil(apiMergerAndAcquisition)) {
      throw new Error('apiMergerAndAcquisition is not a valid object');
    }

    const mAndA: MergerAndAquisition = {
      id: apiMergerAndAcquisition.id.toString(),
      lastStatusDate: apiMergerAndAcquisition.last_status_date,
      status: apiMergerAndAcquisition.status,
      announceDate: apiMergerAndAcquisition.announce_date,
      closingDate: apiMergerAndAcquisition.closing_date,
      dealType: apiMergerAndAcquisition.deal_type as any,
      acquirer: apiMergerAndAcquisition.acquirer,
      target: apiMergerAndAcquisition.target,
      dealValue: apiMergerAndAcquisition.deal_value,
      regionOrPlay: apiMergerAndAcquisition.region_play,
      boePerDay: apiMergerAndAcquisition.boepd,
      pctLiquids: apiMergerAndAcquisition.pct_liquids,
      acreage: apiMergerAndAcquisition.acreage,
      proved: apiMergerAndAcquisition.proved,
      provedPlusProbable: apiMergerAndAcquisition.proved_plus_probable,
      valuePerBoePerDay: apiMergerAndAcquisition.value_per_boepd,
      valuePerAcre: apiMergerAndAcquisition.value_per_acre,
      valuePerProved: apiMergerAndAcquisition.value_per_proved,
      valuePerProvedPlusProbable:
        apiMergerAndAcquisition.value_per_proved_plus_probable,
      links: apiMergerAndAcquisition.links,
    };

    return mAndA;
  },
  toDomainLandSaleDetails: (apiLandSaleDetail) => {
    if (isNil(apiLandSaleDetail)) {
      throw new Error('apiLandSaleDetail is not a valid object');
    }

    // FIXME should map to LandSaleDetails
    const landSale: any = {
      landKey: apiLandSaleDetail.land_key,
      metesBounds: apiLandSaleDetail.metes_bounds,
      metesBoundsIndicator: apiLandSaleDetail.metes_bounds_indicator,
      portion: apiLandSaleDetail.portion,
      tractNumber: apiLandSaleDetail.tract_no,
      landId: apiLandSaleDetail.land_id,
      isAreaPartial: apiLandSaleDetail.is_area_partial_lsd_or_portion,
    };

    return landSale;
  },
};
