import { handleError } from '@pn/core/errors/handleError';
import { useIntelAccess } from '@pn/core/permissions/isIntelAccessDenied';
import { apiMonthlyStatsProvider } from '@pn/services/api/intel/apiMonthlyStatsProvider';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { monthlyStatsActions, useMonthlyStatsStorage } from 'src/storage';

async function getMonthlyProductionData(companyIds: string[]) {
  try {
    monthlyStatsActions().request();

    const monthlyStats =
      await apiMonthlyStatsProvider.getMonthlyStatsByCompanyIds(companyIds);

    monthlyStatsActions().load(companyIds); // remember which companies have been loaded
    monthlyStatsActions().receive(monthlyStats);
  } catch (error) {
    handleError({
      error,
      onError: monthlyStatsActions().error,
      userFriendlyMessage: 'Failed to load monthly production',
    });
  }
}

export function useAutoLoadProductionDataByCompanyIds(companyIds: string[]) {
  const { loadedCompanyIds } = useMonthlyStatsStorage();
  const access = useIntelAccess();
  const isAccessDeniedToMonthlyStats = access('monthlyStats').denied();

  const companyIdsToLoad = React.useMemo(
    () => companyIds.filter((id) => !loadedCompanyIds.includes(id)),
    [companyIds, loadedCompanyIds]
  );

  React.useEffect(() => {
    if (isEmpty(companyIdsToLoad) || isAccessDeniedToMonthlyStats) return;
    getMonthlyProductionData(companyIdsToLoad);
  }, [companyIdsToLoad, isAccessDeniedToMonthlyStats]);
}
