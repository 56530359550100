import { Channel, ChannelForm } from '@pn/core/domain/channel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';

type State = {
  isFetching: boolean;
  isError: boolean;
  channels: Channel[];
};

const initialState: State = {
  isFetching: false,
  isError: false,
  channels: [],
};

export const createChannelsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.isFetching = false;
        state.channels = payload;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.channels = [];
      },
      create: (state, { payload }: PayloadAction<Channel>) => {
        state.channels = [...state.channels, payload];
      },
      update: (
        state,
        {
          payload,
        }: PayloadAction<{ channelId: Channel['id']; channelForm: ChannelForm }>
      ) => {
        const channel = state.channels.find(
          (channel) => channel.id === payload.channelId
        );
        if (isNil(channel)) {
          throw new Error('Channel not found');
        }

        Object.assign(channel, payload.channelForm);
      },
      remove: (state, { payload }: PayloadAction<Channel['id']>) => {
        state.channels = state.channels.filter(({ id }) => id !== payload);
      },
      _updateId: (
        state,
        {
          payload,
        }: PayloadAction<{ currentId: string | number; newId: Channel['id'] }>
      ) => {
        const channel = state.channels.find(
          ({ id }) => id === payload.currentId
        );
        if (isNil(channel))
          throw new Error('Unable to update ID of an undefined channel');
        state.channels.find(({ id }) => id === payload.currentId)!.id =
          payload.newId;
      },
      _replace: (state, { payload }) => {
        return { ...payload };
      },
    },
  });

export const channelsSlice = createChannelsSlice('channels');
export const currentUserChannelsSlice = createChannelsSlice(
  'currentUserChannels'
);
