import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const mineralRightsElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'ID',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 175,
  },
  {
    field: 'agreement_id',
    sourceType: 'keyword',
    label: 'Title/Agreement No.',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 150,
  },
  {
    field: 'agreement_type_id',
    sourceType: 'keyword',
    label: 'Agreement Type ID',
    folder: 'Crown Land Header',
  },
  {
    field: 'tract',
    sourceType: 'keyword',
    label: 'Tract',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 75,
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'Province',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'land_ids',
    sourceType: 'text',
    label: 'Land IDs',
    folder: 'Crown Land Header',
    isNotSortable: true,
    isShownByDefault: true,
    width: 175,
  },
  {
    field: 'zone_description',
    sourceType: 'text',
    label: 'Zone Description',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 250,
  },
  {
    field: 'agreement_type',
    sourceType: 'text',
    label: 'Agreement Type',
    folder: 'Crown Land Header',
  },
  {
    field: 'petro_ninja_agreement_type',
    sourceType: 'text',
    domainTypeAttributes: {
      options: ['PNG', 'OS', 'Coal', 'Metal', 'Geothermal', 'CO2', 'Helium'],
    },
    label: 'Petro Ninja Agreement Type',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 125,
  },
  {
    field: 'participants',
    sourceType: 'text',
    label: 'Owner',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 250,
  },
  {
    field: 'designated_rep',
    sourceType: 'text',
    label: 'Designated Rep',
    folder: 'Crown Land Header',
    width: 250,
  },
  {
    field: 'status',
    sourceType: 'text',
    label: 'Status',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'issue_date',
    sourceType: 'date',
    label: 'Issue Date',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 125,
  },
  {
    field: 'expiry_date',
    sourceType: 'date',
    label: 'Expiry Date',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 125,
  },
  {
    field: 'area',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'ha', // should not be converted
    },
    label: 'Area',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'bonus',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: '$', // should not be converted
    },
    label: 'Bonus',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 105, // fits 7-digit price tags
  },
  {
    field: 'bonus_per_area',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: '$', // should not be converted
    },
    label: 'Bonus/Area',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'term_length',
    sourceType: 'integer',
    label: 'Term Length',
    folder: 'Crown Land Header',
  },
  {
    field: 'continuation_date',
    sourceType: 'date',
    label: 'Continuation Date',
    folder: 'Crown Land Header',
  },
  {
    field: 'cancellation_date',
    sourceType: 'date',
    label: 'Cancellation Date',
    folder: 'Crown Land Header',
  },
  {
    field: 'rental_default_notice_date',
    sourceType: 'date',
    label: 'Rental Default Notice Date',
    folder: 'Crown Land Header',
  },
  /* Not renderable */
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];
