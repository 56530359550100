import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import type { ITableActions, ITableStorage } from './ports';
import { tableSlice } from './tableSlice';

export const useReduxTableStorage = (): ITableStorage => {
  const { name, getInitialState } = tableSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isError: useSliceSelector((state) => state.isError),
    isFetching: useSliceSelector((state) => state.isFetching),
    defaultTableFields: useSliceSelector((state) => state.defaultTableFields),
    tableFields: useSliceSelector((state) => state.tableFields),
    selectedTemplates: useSliceSelector((state) => state.selectedTemplates),
    templates: useSliceSelector((state) => state.templates),
  };
};

export const reduxTableActions = (): ITableActions => {
  const { dispatch } = dependencies.store;
  const { actions } = tableSlice;

  return {
    request: () => dispatch(actions.request()),
    receive: (templates) => dispatch(actions.receive(templates)),
    error: () => dispatch(actions.error()),
    create: (template) => dispatch(actions.create(template)),
    remove: (template) => dispatch(actions.remove(template)),
    select: (templateId) => dispatch(actions.select(templateId)),
    updateDefaultTableFields: (sourceLayerId, fields) =>
      dispatch(actions.updateDefaultTableFields({ sourceLayerId, fields })),
    updateTableFields: (sourceLayerId, fields) =>
      dispatch(actions.updateTableFields({ sourceLayerId, fields })),
    resetTableFields: (sourceLayerId) =>
      dispatch(actions.resetTableFields(sourceLayerId)),
    addTableFields: (sourceLayerId, fields) =>
      dispatch(actions.addTableFields({ sourceLayerId, fields })),
    removeTableFields: (sourceLayerId, fields) =>
      dispatch(actions.removeTableFields({ sourceLayerId, fields })),
  };
};
