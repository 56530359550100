import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { companyDocumentsSlice } from './companyDocumentsSlice';
import type {
  ICompanyDocumentsActions,
  ICompanyDocumentsStorage,
} from './ports';

export const useReduxCompanyDocumentsStorage = (): ICompanyDocumentsStorage => {
  const { name, getInitialState } = companyDocumentsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    documents: useSliceSelector((state) => state.documents),
  };
};

export const reduxCompanyDocumentsActions = (): ICompanyDocumentsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = companyDocumentsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (company) => dispatch(actions.receive(company)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
  };
};
