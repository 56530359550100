import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const wellsUsaElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'API',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'api10',
    sourceType: 'keyword',
    label: 'API (10 Digits)',
    folder: 'Well Header',
  },
  {
    field: 'well_name',
    sourceType: 'text',
    label: 'Well Name',
    folder: 'Well Header',
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'State',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'licensee',
    sourceType: 'text',
    label: 'Licensee',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 220,
  },
  {
    field: 'field_name',
    sourceType: 'text',
    label: 'Field Name',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'producing_formation',
    sourceType: 'text',
    label: 'Producing Formation',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 200,
  },
  {
    field: 'spud_date',
    sourceType: 'date',
    label: 'Spud Date',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 110,
  },
  {
    field: 'completion_date',
    sourceType: 'date',
    label: 'Completion Date',
    folder: 'Well Header',
  },
  {
    field: 'well_status',
    sourceType: 'text',
    label: 'Provincial Status',
    folder: 'Well Header',
  },
  {
    field: 'petro_ninja_well_status',
    sourceType: 'text',
    label: 'Petro Ninja Status',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'lease_name',
    sourceType: 'text',
    label: 'Lease Name',
    folder: 'Well Header',
  },
  {
    field: 'tmd',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'TMD',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 120,
  },
  {
    field: 'tvd',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'TVD',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 120,
  },
  /* Production */
  {
    field: 'cumulative_oil',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Cumulative Oil',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3',
    },
    label: 'Cumulative Gas',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_water',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Cumulative Water',
    folder: 'Production Summary',
  },
  {
    field: 'recent_oil',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Recent Oil',
    folder: 'Production Summary',
    isShownByDefault: true,
  },
  {
    field: 'recent_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3',
    },
    label: 'Recent Gas',
    folder: 'Production Summary',
    isShownByDefault: true,
  },
  {
    field: 'recent_water',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Recent Water',
    folder: 'Production Summary',
  },
  /* Location */
  {
    field: 'surface_latitude',
    sourceType: 'double',
    label: 'Surface Latitude',
    folder: 'Location',
  },
  {
    field: 'surface_longitude',
    sourceType: 'double',
    label: 'Surface Longitude',
    folder: 'Location',
  },
  {
    field: 'bottom_latitude',
    sourceType: 'double',
    label: 'Bottom Latitude',
    folder: 'Location',
  },
  {
    field: 'bottom_longitude',
    sourceType: 'double',
    label: 'Bottom Longitude',
    folder: 'Location',
  },
  /* Not shown */
  {
    field: 'symbol',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  // {
  //   field: 'well_path',
  //   sourceType: 'keyword',
  //   domainType: 'WKT',
  //   label: 'N/A',
  //   folder: 'N/A',
  //   isNotRenderable: true,
  // },
  {
    field: 'well_path_geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];
