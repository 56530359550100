import type { DataItem, DataItemId, MappingItem } from '@pn/core/domain/data';
import type { FilterProperty, Layer, LayerStyle } from '@pn/core/domain/layer';
import {
  areFiltersApplied,
  type DataMultiSelectionReason,
  type DataSelectionReason,
  type Filter,
  type LinkOperator,
  type Query,
  type Sort,
} from '@pn/core/domain/query';
import {
  disableBubbleMapping as _disableBubbleMapping,
  enableBubbleMapping as _enableBubbleMapping,
  createSourceItemMapConfig,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { State } from '@pn/core/storage/workspace/workspaceSlice';
import { findOrThrow } from '@pn/core/utils/logic';
import { CaseReducer, type PayloadAction } from '@reduxjs/toolkit';
import assert from 'assert';
import { isArray, isNil, uniq } from 'lodash-es';
import { moduleItemIds } from './pnWorkspaceItems';

export const add: CaseReducer<
  State,
  PayloadAction<{
    itemOrItems: WorkspaceItem | WorkspaceItem[];
    upsert: boolean;
  }>
> = (state, { payload }) => {
  const items = isArray(payload.itemOrItems)
    ? payload.itemOrItems
    : [payload.itemOrItems];

  state.allWorkspaceItems = smartCombineItems(
    state.allWorkspaceItems as WorkspaceItem[],
    items,
    payload.upsert
  );
};

export const create: CaseReducer<State, PayloadAction<WorkspaceItem>> = (
  state,
  { payload }
) => {
  state.allWorkspaceItems = [payload, ...state.allWorkspaceItems];
};

export const update: CaseReducer<State, PayloadAction<WorkspaceItem>> = (
  state,
  { payload }
) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    ({ id }) => id === payload.id
  );
  Object.assign(item, payload, {
    updatedAt: new Date().toISOString(),
  });
};

export const remove: CaseReducer<State, PayloadAction<WorkspaceItem>> = (
  state,
  { payload }
) => {
  const itemIdsToRemove = [payload.id];
  if (isNil(payload.sourceItem)) {
    const childrenItems = state.allWorkspaceItems.filter(
      ({ sourceItem }) => sourceItem?.id === payload.id
    );
    itemIdsToRemove.push(...childrenItems.map(({ id }) => id));
  }
  state.idsInWorkspace = state.idsInWorkspace.filter(
    (id) => !itemIdsToRemove.includes(id)
  );
  state.allWorkspaceItems = state.allWorkspaceItems.filter(
    ({ id }) => !itemIdsToRemove.includes(id)
  );
};

export const removeStackOriginatedItems: CaseReducer<State> = (state) => {
  state.allWorkspaceItems = state.allWorkspaceItems.filter((item) =>
    isNil(item.metadata?.dataOriginDescription)
  );
  state.idsInWorkspace = state.idsInWorkspace.filter(
    (id) => !isNil(state.allWorkspaceItems.find((item) => item.id === id))
  );
};

export const removeAreasFromWorkspace: CaseReducer<State> = (state) => {
  state.idsInWorkspace = state.idsInWorkspace.filter(
    (id) =>
      !state.allWorkspaceItems.find((item) => item.id === id)?.metadata
        ?.isQueryArea
  );
};

export const updateInvalidDataItemIds: CaseReducer<
  State,
  PayloadAction<DataItem['_id'][]>
> = (state, { payload }) => {
  state.invalidDataItemIds = payload;
};

export const updateIsFetching: CaseReducer<State, PayloadAction<boolean>> = (
  state,
  { payload }
) => {
  state.isFetching = payload;
};

export const updateIsFetchingStack: CaseReducer<
  State,
  PayloadAction<boolean>
> = (state, { payload }) => {
  state.isFetchingStack = payload;
};

export const updateQueryProperty: CaseReducer<
  State,
  PayloadAction<{ itemId: string; query: Partial<Query> }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  item.query = {
    ...item.query,
    ...payload.query,
  };
};

export const updateFilterPropertyOptions: CaseReducer<
  State,
  PayloadAction<{
    itemId: string;
    filterPropertyOptions: FilterProperty['options'];
  }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  assert(
    item.map.filterProperty,
    'Cannot update filter property options of an item with no filter property'
  );
  item.map.filterProperty.options = payload.filterPropertyOptions;
};

export const addLayer: CaseReducer<
  State,
  PayloadAction<{ itemId: string; layer: Layer }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  item.map.layers.push(payload.layer);
  item.isProcessed = false;
};

export const removeLayer: CaseReducer<
  State,
  PayloadAction<{ itemId: string; layerId: string }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  item.map.layers = item.map.layers.filter(({ id }) => id !== payload.layerId);
  item.isProcessed = false;
};

export const updateLayerStyle: CaseReducer<
  State,
  PayloadAction<{ itemId: string; layerId: string; style: LayerStyle }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  const layer = findOrThrow(
    item.map.layers,
    ({ id }) => id === payload.layerId
  );
  Object.assign(layer.style, payload.style);
  item.isProcessed = false;
};

export const updateAllLayerStyles: CaseReducer<
  State,
  PayloadAction<{ itemId: string; style: LayerStyle }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  ) as WorkspaceItem;
  item.map = createSourceItemMapConfig({
    mode: 'duplicate',
    sourceItem: item,
    newLayerStyle: payload.style,
  });
  item.isProcessed = false;
};

export const enableBubbleMapping: CaseReducer<
  State,
  PayloadAction<{ itemId: string; radius: unknown[]; color?: unknown }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  _enableBubbleMapping(item as WorkspaceItem, {
    radius: payload.radius,
    color: payload.color,
  });
  item.isProcessed = false;
};

export const disableBubbleMapping: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload
  );
  _disableBubbleMapping(item as WorkspaceItem);
  item.isProcessed = false;
};

export const updateNumberOfElements: CaseReducer<
  State,
  PayloadAction<{ itemId: string; numberOfElements: number }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    ({ id }) => id === payload.itemId
  );
  item.numberOfElements = payload.numberOfElements;
};

export const updateName: CaseReducer<
  State,
  PayloadAction<{ itemId: string; name: string }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    ({ id }) => id === payload.itemId
  );
  item.name = payload.name;
};

export const addToWorkspace: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.idsInWorkspace = uniq([...state.idsInWorkspace, payload]);
  const item = state.allWorkspaceItems.find(({ id }) => id === payload);
  if (item) {
    item.isVisible = true;
  }
};

export const removeFromWorkspace: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.idsInWorkspace = state.idsInWorkspace.filter((id) => id !== payload);
  const item = state.allWorkspaceItems.find(({ id }) => id === payload);
  if (item) {
    item.isProcessed = false;
  }
};

export const setWorkspace: CaseReducer<
  State,
  PayloadAction<{ itemIds: string[]; preserveVisibility?: boolean }>
> = (state, { payload }) => {
  state.idsInWorkspace = prependModuleItemIds(payload.itemIds);
  const items = state.allWorkspaceItems.filter(({ id }) =>
    payload.itemIds.includes(id)
  );
  if (!payload.preserveVisibility) {
    items.forEach((item) => {
      item.isVisible = true;
    });
  }
};

export const select: CaseReducer<State, PayloadAction<string | undefined>> = (
  state,
  { payload }
) => {
  state.workspaceItemIdSelected = payload;
};

export const updateCounts: CaseReducer<
  State,
  PayloadAction<{ itemId: string; totalCount: number; streamedCount: number }>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload.itemId);
  if (!isNil(item)) {
    item.totalCount = payload.totalCount;
    item.streamedCount = payload.streamedCount;
  }
};

export const updateVisibility: CaseReducer<
  State,
  PayloadAction<{ itemId: string; isVisible: boolean }>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload.itemId);
  if (!isNil(item)) item.isVisible = payload.isVisible;
};

export const updateMapping: CaseReducer<
  State,
  PayloadAction<{ itemId: string; mapping: MappingItem[] }>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(
    (item) => item.id === payload.itemId
  );
  if (!isNil(item)) {
    item.mapping = payload.mapping;
    item.isMappingInitialized = true;
  }
};

export const updateTableDataItems: CaseReducer<
  State,
  PayloadAction<{ itemId: string; tableDataItems: DataItem[] }>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload.itemId);
  if (!isNil(item))
    (item as WorkspaceItem).tableDataItems = payload.tableDataItems;
};

export const setRequestedDataItemId: CaseReducer<
  State,
  PayloadAction<{ itemId: string; id: DataItemId; reason: DataSelectionReason }>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload.itemId);
  if (!isNil(item))
    item.requestedDataItem = {
      id: payload.id,
      reason: payload.reason,
    };
};

export const unsetRequestedDataItemId: CaseReducer<
  State,
  PayloadAction<string>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload);
  if (!isNil(item)) {
    item.requestedDataItem = { id: undefined, reason: undefined };
  }
};

export const updateIsTemporaryShared: CaseReducer<
  State,
  PayloadAction<{ itemId: string; checked: boolean }>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload.itemId);
  if (!isNil(item)) item._isTemporaryShared = payload.checked;
};

export const markAsLoaded: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload);
  if (!isNil(item)) item.isLoaded = true;
};

export const markAsProcessed: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload);
  if (!isNil(item)) item.isProcessed = true;
};

export const markAsUnfinalized: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload);
  if (!isNil(item)) item.isRendered = false;
};

export const markAsRendered: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload);
  if (!isNil(item)) item.isRendered = true;
};

export const revisualize: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload);
  if (!isNil(item)) {
    item.isProcessed = false;
    item.isRendered = false;
    item.tableDataItems = [];
  }
};

export const resetInternalState: CaseReducer<State> = (state) => {
  state.allWorkspaceItems.forEach((item) => {
    item._isTemporaryShared = false;
    item.isVisible = true;
    item.isProcessed = false;
    item.isRendered = false;
    item.totalCount = 0;
    item.streamedCount = 0;
    item.tableDataItems = [];
    item.requestedDataItem = {
      id: undefined,
      reason: undefined,
    };
  });
};

export const updateSorts: CaseReducer<
  State,
  PayloadAction<{ itemId: string; sorts: Sort[] }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  item.query.sorts = payload.sorts;
  item.isProcessed = false;
};

export const updateFiltering: CaseReducer<
  State,
  PayloadAction<{
    itemId: string;
    filters: Filter[];
    linkOperator: LinkOperator;
  }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  item.query.filters = payload.filters;
  item.query.filtersLinkOperator = payload.linkOperator;
  if (areFiltersApplied(payload.filters)) {
    item.query.page = 0;
  }
  item.isProcessed = false;
};

export const updatePage: CaseReducer<
  State,
  PayloadAction<{ itemId: string; page: number }>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(({ id }) => id === payload.itemId);
  if (!isNil(item)) {
    item.query.page = payload.page;
    item.isProcessed = false;
  }
};

export const setCheckedIds: CaseReducer<
  State,
  PayloadAction<{ itemId: string; ids: DataItemId[] }>
> = (state, { payload }) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload.itemId
  );
  item.query.checkedIds = payload.ids;
};

export const updateRequestedIds: CaseReducer<
  State,
  PayloadAction<{
    itemId: string;
    ids: DataItemId[];
    reason: DataMultiSelectionReason | DataSelectionReason | undefined;
  }>
> = (state, { payload }) => {
  const item = state.allWorkspaceItems.find(
    (item) => item.id === payload.itemId
  );
  if (!isNil(item)) {
    item.query.requestedIds = payload.ids;
    item.query.multiSelectionReason = payload.reason;
    item.isProcessed = false;
  }
};

export const overrideLimit: CaseReducer<State, PayloadAction<string>> = (
  state,
  { payload }
) => {
  const item = findOrThrow(
    state.allWorkspaceItems,
    (item) => item.id === payload
  );
  item.query.ignoreLimit = true;
  item.isProcessed = false;
};

export const _replace: CaseReducer<State, PayloadAction<State>> = (
  _state,
  { payload }
) => {
  return { ...payload };
};

/* HELPERS */

function smartCombineItems(
  items: WorkspaceItem[],
  incomingItems: WorkspaceItem[],
  upsert: boolean
): WorkspaceItem[] {
  const newItems = incomingItems.filter(
    (newItem) => !items.find(({ id }) => id === newItem.id)
  );

  if (!upsert) return [...items, ...newItems];

  return [
    ...items.map((item) => {
      const newItem = incomingItems.find(({ id }) => id === item.id);
      return isNil(newItem) ? item : newItem;
    }),
    ...newItems,
  ];
}

/**
 * Ensure `_background` and `grids` are always present.
 * If not specified, they are prepended at the beginning of the array.
 */
function prependModuleItemIds(
  itemIds: WorkspaceItem['id'][]
): WorkspaceItem['id'][] {
  const ids = [...itemIds];

  for (let i = moduleItemIds.length - 1; i >= 0; i--) {
    const id = moduleItemIds[i];
    if (!itemIds.includes(id)) {
      ids.unshift(id);
    }
  }

  return ids;
}
