import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { companyQuarterlyStatsSlice } from './companyQuarterlyStatsSlice';
import type {
  IQuarterlyStatisticsActions,
  IQuarterlyStatisticsStorage,
} from './ports';

export const useReduxQuarterlyStatsStorage =
  (): IQuarterlyStatisticsStorage => {
    const { name, getInitialState } = companyQuarterlyStatsSlice;

    const useSliceSelector = useBuildSliceSelector(name, getInitialState());

    return {
      isFetching: useSliceSelector((state) => state.isFetching),
      isError: useSliceSelector((state) => state.isError),
      stats: useSliceSelector((state) => state.stats),
    };
  };

export const reduxQuarterlyStatsActions = (): IQuarterlyStatisticsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = companyQuarterlyStatsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (stats) => dispatch(actions.receive(stats)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    add: (stat) => dispatch(actions.add(stat)),
    update: (statId, stat) => dispatch(actions.update({ statId, stat })),
    remove: (statId) => dispatch(actions.remove(statId)),
  };
};
