import { reduxAppActions, useReduxAppStorage } from './app/appStorage';
import {
  reduxChannelsActions,
  useReduxChannelsStorage,
} from './channel/channelsStorage';
import {
  reduxCompaniesActions,
  useReduxCompaniesStorage,
} from './company/companiesStorage';
import {
  reduxDataDetailsActions,
  reduxDataDocumentsActions,
  useReduxDataDetailsStorage,
  useReduxDataDocumentsStorage,
} from './data-info/dataInfoStorage';
import {
  reduxCurrentEnterpriseActions,
  useReduxCurrentEnterpriseStorage,
} from './enterprise/currentEnterpriseStorage';
import {
  reduxEnterprisesActions,
  useReduxEnterprisesStorage,
} from './enterprise/enterprisesStorage';
import {
  reduxCompanyDocumentsActions,
  useReduxCompanyDocumentsStorage,
} from './intel/companyDocumentsStorage';
import {
  reduxExtraCompanyInfoActions,
  useReduxExtraCompanyInfoStorage,
} from './intel/companyExtraInfoStorage';
import {
  reduxQuarterlyStatsActions,
  useReduxQuarterlyStatsStorage,
} from './intel/companyQuarterlyStatsStorage';
import {
  reduxRealTimeStockActions,
  useReduxRealTimeStockStorage,
} from './intel/realTimeStockStorage';
import { reduxMapActions, useReduxMapStorage } from './map/mapStorage';
import {
  reduxProductionActions,
  useReduxProductionStorage,
} from './production/productionStorage';
import {
  reduxProjectsActions,
  useReduxProjectsStorage,
} from './project/projectsStorage';
import {
  reduxSearchActions,
  useReduxSearchStorage,
} from './search/searchStorage';
import { reduxTableActions, useReduxTableStorage } from './table/tableStorage';
import {
  reduxCurrentUserActions,
  useReduxCurrentUserStorage,
} from './user/currentUserStorage';
import {
  reduxEnterpriseUsersActions,
  useReduxEnterpriseUsersStorage,
} from './user/enterpriseUsersStorage';
import {
  reduxInvitedUsersActions,
  useReduxInvitedUsersStorage,
} from './user/invitedUsersStorage';
import {
  reduxWorkspaceActions,
  useReduxWorkspaceStorage,
} from './workspace/workspaceStorage';
import {
  reduxIntelAlertsActions,
  useReduxIntelAlertsStorage,
} from '@pn/core/storage/intel/intelAlertsStorage.ts';

export const useAppStorage = useReduxAppStorage;
export const appActions = reduxAppActions;
/* Enterprises */
export const useEnterprisesStorage = useReduxEnterprisesStorage;
export const enterprisesActions = reduxEnterprisesActions;
export const useCurrentEnterpriseStorage = useReduxCurrentEnterpriseStorage;
export const currentEnterpriseActions = reduxCurrentEnterpriseActions;
/* Users */
export const useCurrentUserStorage = useReduxCurrentUserStorage;
export const currentUserActions = reduxCurrentUserActions;
export const useEnterpriseUsersStorage = useReduxEnterpriseUsersStorage;
export const enterpriseUsersActions = reduxEnterpriseUsersActions;
export const useInvitedUsersStorage = useReduxInvitedUsersStorage;
export const invitedUsersActions = reduxInvitedUsersActions;
/* Projects */
export const useProjectsStorage = useReduxProjectsStorage;
export const projectsActions = reduxProjectsActions;
/* Workspace */
export const useWorkspaceStorage = useReduxWorkspaceStorage;
export const workspaceActions = reduxWorkspaceActions;
/* Channels */
export const useChannelsStorage = useReduxChannelsStorage;
export const channelsActions = reduxChannelsActions;
/* Map */
export const useMapStorage = useReduxMapStorage;
export const mapActions = reduxMapActions;
/* Table */
export const useTableStorage = useReduxTableStorage;
export const tableActions = reduxTableActions;
/* Search */
export const useSearchStorage = useReduxSearchStorage;
export const searchActions = reduxSearchActions;
/* Data Info */
export const useDataDetailsStorage = useReduxDataDetailsStorage;
export const dataDetailsActions = reduxDataDetailsActions;
export const useDataDocumentsStorage = useReduxDataDocumentsStorage;
export const dataDocumentsActions = reduxDataDocumentsActions;
/* Production */
export const useProductionStorage = useReduxProductionStorage;
export const productionActions = reduxProductionActions;
export const useCompaniesStorage = useReduxCompaniesStorage;
export const companiesActions = reduxCompaniesActions;
/* BOE Intel */
export const useExtraCompanyInfoStorage = useReduxExtraCompanyInfoStorage;
export const extraCompanyInfoActions = reduxExtraCompanyInfoActions;
export const useRealTimeStockStorage = useReduxRealTimeStockStorage;
export const realTimeStockActions = reduxRealTimeStockActions;
export const useQuarterlyStatsStorage = useReduxQuarterlyStatsStorage;
export const quarterlyStatsActions = reduxQuarterlyStatsActions;
export const useCompanyDocumentsStorage = useReduxCompanyDocumentsStorage;
export const companyDocumentsActions = reduxCompanyDocumentsActions;
export const intelAlertsActions = reduxIntelAlertsActions;
export const useIntelAlertsStorage = useReduxIntelAlertsStorage;