import type { DataItem } from '@pn/core/domain/data';
import type {
  ScrollableQuery,
  StreamableQuery,
} from '@pn/core/domain/query';
import { log } from '@pn/core/utils/debug';
import { hasKeyWithType } from '@pn/core/utils/logic';
import { isEmpty, isNil, isNumber, isString, orderBy, uniqBy } from 'lodash-es';

/**
 * Local implementation of data processor.
 */
export function processByQuery(params: {
  localData: DataItem[];
  queryType: 'scroll' | 'stream';
  query: ScrollableQuery | StreamableQuery;
}) {
  const { localData, queryType, query } = params;
  log.time(`processByQuery ${queryType}`);

  let processedData = localData;

  const { sorts, filters, filtersLinkOperator, requestedIds } = query;

  if (!isEmpty(requestedIds)) {
    processedData = processedData.filter((dataItem) =>
      requestedIds.includes(dataItem._id)
    );
  }

  if (queryType === 'scroll' && !isEmpty(sorts)) {
    const { field, direction } = sorts[0];

    processedData = orderBy(
      processedData,
      (dataItem) => dataItem[field],
      direction
    );
  }

  if (!isEmpty(filters)) {
    let allFilteredData: DataItem[] = [];

    filters.forEach((filter) => {
      const { field, operator, value } = filter;

      const filterFn = getFilterFn(operator);
      const filteredData = processedData.filter((dataItem) =>
        filterFn(dataItem[field], value)
      );

      if (filtersLinkOperator === 'and') {
        processedData = filteredData;
      } else {
        allFilteredData = uniqBy([...allFilteredData, ...filteredData], '_id');
      }
    });

    if (filtersLinkOperator === 'or') {
      processedData = allFilteredData;
    }
  }

  if (
    queryType === 'scroll' &&
    hasKeyWithType(query, 'page', isNumber) &&
    hasKeyWithType(query, 'pageSize', isNumber)
  ) {
    const pageOffset = query.page * query.pageSize;

    return {
      data: processedData.slice(pageOffset, pageOffset + query.pageSize),
      totalCount: processedData.length,
      page: query.page,
    };
  }

  log.timeEnd(`processByQuery ${queryType}`);

  return {
    data: processedData,
    totalCount: processedData.length,
    page: -1, // unused
  };
}

function getFilterFn(operator: string) {
  switch (operator) {
    /* Generic operators (boolean, DateString) */
    case 'is':
      return (fieldValue: unknown, filterValue: unknown) => {
        return fieldValue === filterValue;
      };

    /* Numeric operators */
    case '=':
      return (fieldValue: unknown, filterValue: unknown) => {
        if (isNil(fieldValue)) return false;

        if (!isNumber(fieldValue))
          throw new Error(
            `Invalid field value [${fieldValue}] (expected number)`
          );
        if (!isNumber(filterValue))
          throw new Error(
            `Invalid filter value [${filterValue}] (expected number)`
          );

        return fieldValue === filterValue;
      };
    case '>=':
      return (fieldValue: unknown, filterValue: unknown) => {
        if (isNil(fieldValue)) return false;

        if (!isNumber(fieldValue))
          throw new Error(
            `Invalid field value [${fieldValue}] (expected number)`
          );
        if (!isNumber(filterValue))
          throw new Error(
            `Invalid filter value [${filterValue}] (expected number)`
          );

        return fieldValue >= filterValue;
      };
    case '<=':
      return (fieldValue: unknown, filterValue: unknown) => {
        if (isNil(fieldValue)) return false;

        if (!isNumber(fieldValue))
          throw new Error(
            `Invalid field value [${fieldValue}] (expected number)`
          );
        if (!isNumber(filterValue))
          throw new Error(
            `Invalid filter value [${filterValue}] (expected number)`
          );

        return fieldValue <= filterValue;
      };

    /* String operators */
    case 'contains':
      return (fieldValue: unknown, filterValue: unknown) => {
        if (isNil(fieldValue)) return false;

        if (!isString(fieldValue))
          throw new Error(
            `Invalid field value [${fieldValue}] (expected string)`
          );
        if (!isString(filterValue))
          throw new Error(
            `Invalid filter value [${filterValue}] (expected string)`
          );

        return fieldValue.toLowerCase().includes(filterValue.toLowerCase());
      };
    case 'notContains':
      return (fieldValue: unknown, filterValue: unknown) => {
        if (isNil(fieldValue)) return false; // undefined values are always filtered out

        if (!isString(fieldValue))
          throw new Error(
            `Invalid field value [${fieldValue}] (expected string)`
          );
        if (!isString(filterValue))
          throw new Error(
            `Invalid filter value [${filterValue}] (expected string)`
          );

        return !fieldValue.toLowerCase().includes(filterValue.toLowerCase());
      };

    /* DateString operators */
    case 'onOrAfter':
      return (fieldValue: unknown, filterValue: unknown) => {
        if (isNil(fieldValue)) return false;

        if (!isString(fieldValue))
          throw new Error(
            `Invalid field value [${fieldValue}] (expected string)`
          );
        if (!isString(filterValue))
          throw new Error(
            `Invalid filter value [${filterValue}] (expected string)`
          );

        return parseInt(fieldValue) >= parseInt(filterValue);
      };
    case 'onOrBefore':
      return (fieldValue: unknown, filterValue: unknown) => {
        if (isNil(fieldValue)) return false;

        if (!isString(fieldValue))
          throw new Error(
            `Invalid field value [${fieldValue}] (expected string)`
          );
        if (!isString(filterValue))
          throw new Error(
            `Invalid filter value [${filterValue}] (expected string)`
          );

        return parseInt(fieldValue) <= parseInt(filterValue);
      };
    default:
      throw new Error(`Unhandled filter operator: ${operator}`);
  }
}
