import { appSlice } from '@pn/core/storage/app/appSlice';
import { tableSlice } from '@pn/core/storage/table/tableSlice';
import type { Slice } from '@reduxjs/toolkit';

// NOTE persistence only works for non-injectable reducers

export type Persistence = {
  slice: Slice;
  keys: string[];
};

const app = {
  slice: appSlice,
  keys: ['unitSystem'],
};

const tableFields = {
  slice: tableSlice,
  keys: ['tableFields', 'selectedTemplates'],
};

export const persistence: Persistence[] = [app, tableFields];
