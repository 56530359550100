import { StockInfo } from '@pn/core/domain/intel/intel';
import { CorporateQuarterlyStatistic } from '@pn/core/domain/intel/corporate-production';
import { IIntelProvider } from '@pn/core/providers/intel/ports';
import { isEmpty, reverse } from 'lodash-es';
import { apiCompanyInfoMapper } from './apiCompanyInfoMapper';
import {
  ApiBOEReportArticle,
  ApiCompanyDocument,
  ApiCompanyExecutive,
  ApiCompanyKPI,
  ApiDailyHistoricalStockPrices,
  ApiExtraCompanyInfo,
  ApiMineralRightsTransferDetail,
  ApiQuarterlyFinancialStatement,
  ApiQuarterlyStat,
} from './types';
import { ApiMergerAndAcquisition } from '../intel-data/types';
import { apiIntelDataMapper } from '../intel-data/apiIntelDataMapper';
import { pnApiClient } from '../pnApiClient';
import { Company } from '@pn/core/domain/intel/company';
import { LandSaleDetails } from '@pn/core/domain/intel/land-sale';

export const requestAllQuarterlyStatistics = async () => {
  const apiQuarterlyStats = await pnApiClient.request<ApiQuarterlyStat[]>({
    url: 'v1/market_intel/quarterly_stats',
  });

  const domainQuarterlyStatistics: CorporateQuarterlyStatistic[] =
    apiQuarterlyStats.map((apiQuarterlyStat) =>
      apiCompanyInfoMapper.toDomainQuarterlyStat(apiQuarterlyStat)
    );

  return domainQuarterlyStatistics;
};

export const requestCompanyExtraInfo = async (
  companyStockSymbol: Company['stockSymbol']
) => {
  const apiCompanyIntelPromise = pnApiClient.request<ApiExtraCompanyInfo[]>({
    url: `v1/market_intel/external_api?request=/api/v3/profile/${companyStockSymbol}`,
  });

  const apiCompanyExecutivesPromise = pnApiClient.request<
    ApiCompanyExecutive[]
  >({
    url: `v1/market_intel/external_api?request=/api/v3/key-executives/${companyStockSymbol}`,
  });

  const apiStockPricesPromise = pnApiClient.request<{
    historical: ApiDailyHistoricalStockPrices[];
  }>({
    url: `v1/market_intel/external_api?request=/api/v3/historical-price-full/${companyStockSymbol}?serietype=line`,
  });

  const apiFinancesPromise = pnApiClient.request<
    ApiQuarterlyFinancialStatement[]
  >({
    url: `v1/market_intel/external_api?request=/api/v3/income-statement/${companyStockSymbol}?period=quarter&limit=${
      4 * 10
    }`,
  });

  const results = await Promise.all([
    apiCompanyIntelPromise,
    apiCompanyExecutivesPromise,
    apiStockPricesPromise,
    apiFinancesPromise,
  ]);

  return apiCompanyInfoMapper.toDomainCompanyInfo({
    ...results[0][0],
    executives: results[1],
    historical: reverse(results[2].historical),
    quarterlyFinancialStatements: results[3],
  });
};

export const requestCompanyStockInformation = async (
  companyStockSymbol: Company['stockSymbol']
) => {
  const apiCompanyStockChange = await pnApiClient.request<
    StockInfo['changes'][]
  >({
    url: `v1/market_intel/external_api?request=/api/v3/stock-price-change/${companyStockSymbol}`,
  });

  const apiCompanyRealTimeStockPrice = await pnApiClient.request<
    { price: number }[]
  >({
    url: `v1/market_intel/external_api?request=/api/v3/quote-short/${companyStockSymbol}`,
  });

  const currentPrice = !isEmpty(apiCompanyRealTimeStockPrice)
    ? apiCompanyRealTimeStockPrice[0].price
    : 0;

  const changes = !isEmpty(apiCompanyStockChange[0])
    ? apiCompanyStockChange[0]
    : { '3Y': 0, '2. high': 0, '3. low': 0, '4. close': 0 };

  // should use a mapper but for the sake of time, I'm just going to return the data
  return {
    changes,
    price: currentPrice,
    time: new Date().toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    }),
  };
};

export const requestCompanyDocuments = async (companyId: Company['id']) => {
  const apiCompanyDocuments = await pnApiClient.request<ApiCompanyDocument[]>({
    url: `v1/market_intel/documents?company=${companyId}`,
  });
  return apiCompanyDocuments.map((apiCompanyDocument) => {
    return apiCompanyInfoMapper.toDomainCompanyDocument(apiCompanyDocument);
  });
};

export const requestCompanyBOEArticles = async (
  companyBOEArticleLink: Company['boeReportLink']
) => {
  const apiCompanyDocuments = await pnApiClient.request<ApiBOEReportArticle[]>({
    url: `v1/market_intel/boe_articles?company=${companyBOEArticleLink}`,
  });
  return apiCompanyDocuments.map((apiCompanyDocument) => {
    return apiCompanyInfoMapper.toDomainBOEReportArticle(apiCompanyDocument);
  });
};

export const requestIntelStripePlans = async () => {
  return [];
};

export const requestMineralRightsTransferDetails = async (id: string) => {
  const apiMineralRightsTransferDetails = await pnApiClient.request<
    ApiMineralRightsTransferDetail[]
  >({
    url: `v1/market_intel/mineral_rights_transfers/${id}`,
  });

  return apiMineralRightsTransferDetails.map((detail) => {
    return {
      agreementId: detail.agreement_id,
      fromDOI: detail.from_doi,
      toDOI: detail.to_doi,
      areaHa: detail.area_ha,
      province: detail.province,
      landTractList: detail.land_tract_list.map((tract) => {
        return {
          petroninjaLandId: tract.petroninja_land_id,
          tract: tract.tract,
          landId: tract.land_id,
          areaHa: tract.area_ha,
        };
      }),
    };
  });
};

export const requestCompanyKPIs = async (companyIds: Company['id'][]) => {
  const apiCompanyKPIs = await pnApiClient.request<ApiCompanyKPI[]>({
    url: 'v1/market_intel/company_kpis?company=' + companyIds.join(','),
  });

  return apiCompanyKPIs.map((apiCompanyKPI) => {
    return apiCompanyInfoMapper.toDomainCompanyKPI(apiCompanyKPI);
  });
};
export const requestAllCompanyKPIs = async () => {
  const apiCompanyKPIs = await pnApiClient.request<ApiCompanyKPI[]>({
    url: 'v1/market_intel/company_kpis/show_table_stats',
  });

  return apiCompanyKPIs.map((apiCompanyKPI) => {
    return apiCompanyInfoMapper.toDomainCompanyKPI(apiCompanyKPI);
  });
};

export const requestCompanyLastClosingStockPrice = async (
  companyStockSymbol: Company['stockSymbol']
) => {
  const apiCompanyStockChange = await pnApiClient.request<
    | {
        symbol: string;
        historical: {
          close: number;
        }[];
      }
    | undefined
  >({
    url: `v1/market_intel/external_api?request=/api/v3/historical-price-full/${companyStockSymbol}`,
  });

  return apiCompanyStockChange?.historical?.at(0)?.close ?? -1;
};

export const requestMergersAndAcquisitions = async () => {
  const apiMergersAndAcquisitions = await pnApiClient.request<
    ApiMergerAndAcquisition[]
  >({
    url: 'v1/market_intel/ma',
  });

  return apiMergersAndAcquisitions.map((apiWellLicenseTransfer) => {
    return apiIntelDataMapper.toDomainMergerAndAcquisition({
      ...apiWellLicenseTransfer,
    });
  });
};

export const requestLandSaleOfferDates = async () => {
  const apiLandSaleOfferDate = await pnApiClient.request<
    { notice_date: string }[]
  >({
    url: 'v1/market_intel/land_sales/dates',
  });

  const dates = apiLandSaleOfferDate.reduce<string[]>((acc, curr) => {
    return [...acc, curr.notice_date];
  }, []);

  dates.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);

    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
    return 0;
  });
  return dates;
};

export const requestLandSaleDetails = async (
  noticeDate: string,
  contractNumber: string
) => {
  return await pnApiClient.request<LandSaleDetails>({
    url:
      'v1/market_intel/land_sales/' +
      contractNumber +
      '/' +
      noticeDate +
      '/details',
  });
};

export const apiCompanyInfoProvider: IIntelProvider = {
  getCompanyKPIs: requestCompanyKPIs,
  getAllCompanyKPIs: requestAllCompanyKPIs,
  getQuarterlyStatistics: requestAllQuarterlyStatistics,
  getCompanyExtraInfo: requestCompanyExtraInfo,
  getCompanyStockInformation: requestCompanyStockInformation,
  getCompanyLastClosingStockPrice: requestCompanyLastClosingStockPrice,
  getCompanyDocuments: requestCompanyDocuments,
  getIntelStripePlans: requestIntelStripePlans,
  getBOEReportArticles: requestCompanyBOEArticles,
  getMineralRightsTransferDetails: requestMineralRightsTransferDetails,
  getMergersAndAcquisitions: requestMergersAndAcquisitions,
  getLandSaleDetails: requestLandSaleDetails,
  getLandSaleOfferDates: requestLandSaleOfferDates,
};
