import { every, isNil, isNumber, orderBy, round, sum } from 'lodash-es';
import { generateTemporaryId } from '@pn/core/utils/id';
import { ExtendedProductionType, ProductionStatistic } from './production';

/**
 * Quarterly statistic reported by companies
 */
export type CorporateQuarterlyStatistic = ProductionStatistic & {
  id: string | number;
  companyId: string;
  year: number;
  quarter: number;
  gAndA: number | null; // general and administrative costs
  operatingExpense: number | null; // operating expense
  netback: number | null; // netback
  transportationCostsBOED: number | null; // transportation costs per barrels of oil equivalent per day
  royaltyCostsBOED: number | null; // royalty costs per barrels of oil equivalent per day
  hedgingGainLossBOED: number | null; // hedging gain or loss per barrels of oil equivalent per day
  marketCapitalization: number | null;
  netDebt: number | null;
  enterprise: number | null;
  cashFromOperations: number | null;
  capitalExpenditure: number | null;
  oilRealizedPriceCADPerBBL: number | null;
  naturalGasRealizedPriceCADPerMCF: number | null;
  condensateRealizedPriceCADPerBBL: number | null;
  nglRealizedPriceCADPerBBL: number | null;
  oilEquivalentRealizedPriceCADPerBOE: number | null;
  shares: number | null;
  dividendsPaidMCAD: number | null;
  decommissioningObligationsMCD: number | null;
  shareBuybacksMCAD: number | null;
  dAndDCadPerBOE: number | null; // decommissioning and abandonment costs per barrels of oil equivalent per day
  financingCostsCADPerBOE: number | null; // financing costs per barrels of oil equivalent per day
  shareBasedCompensationCADPerBOE: number | null; // share based compensation per barrels of oil equivalent per day
};

export function createQuarterlyStat(
  year: number,
  quarter: number,
  companyId: string
): CorporateQuarterlyStatistic {
  return {
    id: generateTemporaryId(),
    companyId,
    year,
    quarter,
    oilBBLD: null,
    naturalGasMMCF: null,
    condensateBBLD: null,
    naturalGasLiquidsBBLPerDay: null,
    liquidProductionsBBLD: null,
    oilEquivalentBOED: null,
    gAndA: null,
    operatingExpense: null,
    netback: null,
    transportationCostsBOED: null,
    royaltyCostsBOED: null,
    hedgingGainLossBOED: null,
    marketCapitalization: null,
    netDebt: null,
    enterprise: null,
    cashFromOperations: null,
    capitalExpenditure: null,
    oilRealizedPriceCADPerBBL: null,
    naturalGasRealizedPriceCADPerMCF: null,
    condensateRealizedPriceCADPerBBL: null,
    nglRealizedPriceCADPerBBL: null,
    oilEquivalentRealizedPriceCADPerBOE: null,
    shares: null,
    dividendsPaidMCAD: null,
    decommissioningObligationsMCD: null,
    shareBuybacksMCAD: null,
    dAndDCadPerBOE: null,
    financingCostsCADPerBOE: null,
    shareBasedCompensationCADPerBOE: null,
  };
}

export function isQuarterlyStat(
  stat: unknown
): stat is CorporateQuarterlyStatistic {
  return (
    typeof stat === 'object' &&
    stat !== null &&
    'id' in stat &&
    'companyId' in stat &&
    'year' in stat
  );
}

export type CorporateQuarterlyStatisticView = CorporateQuarterlyStatistic &
  ExtendedProductionType & {
    readonly yearAndQuarter: number; // year and quarter expressed as a number (e.g. 20201 for Q1 2020)
    readonly formattedYearAndQuarter: string; // year and quarter expressed as a string (e.g. 2020-Q1)
    netDebtOverLTMOCF: number | null; // net debt over last twelve months of operating cash flow
    readonly netDebtOverCash: number | null; // net debt over cash
    readonly positiveCapExp: number | null; // capital expenditure as a positive number
    // barrels of natural gas liquids and condensate per day
    readonly nglAndCnd: number | null;
    readonly evPerBOEPerDay: number | null; // enterprise value per barrels of oil equivalent per day
    readonly evOverOCF: number | null; // enterprise value over operating cash flow
    // debt over cash flow
    readonly debtOverCashFlow: number | null;
  };

export function getPercentChangeInProduction(
  stats: CorporateQuarterlyStatisticView[] | undefined
) {
  if (isNil(stats) || stats.length < 4) return null;
  const orderedStats = orderBy(stats, ['year', 'quarter'], ['asc', 'asc']);
  const mostRecentProduction = orderedStats.at(-1)?.oilEquivalentBOED;
  const productionFromTwoQuartersAgo = orderedStats.at(-3)?.oilEquivalentBOED;

  if (!isNil(mostRecentProduction) && !isNil(productionFromTwoQuartersAgo)) {
    // return percent change
    return round(
      ((mostRecentProduction - productionFromTwoQuartersAgo) /
        productionFromTwoQuartersAgo) *
        100
    );
  }
  return null;
}

export function getEnterpriseValue(
  shares: number,
  netDebt: number,
  stockPrice: number
) {
  if (isNil(shares) || isNil(netDebt) || isNil(stockPrice)) {
    return null;
  }

  return shares * stockPrice + netDebt;
}

export function getEVPerBOEPerDay(
  { shares, oilEquivalentBOED, netDebt }: CorporateQuarterlyStatisticView,
  stockPrice: number
) {
  if (isNil(shares) || isNil(oilEquivalentBOED) || isNil(netDebt)) {
    return null;
  }

  const enterpriseValue = getEnterpriseValue(shares, netDebt, stockPrice); // enterprise value
  if (isNil(enterpriseValue)) return null;

  return round(enterpriseValue / oilEquivalentBOED);
}

export function getEvOverOCF(
  stats: CorporateQuarterlyStatisticView[],
  stockPrice: number
) {
  const fourMostRecentOperatingCashFlows = orderBy(
    stats.filter((stat) => isNumber(stat.cashFromOperations)),
    ['yearAndQuarter'],
    ['desc']
  ).slice(0, 4);

  if (fourMostRecentOperatingCashFlows.length !== 4) return null;

  const sumOfOCF = fourMostRecentOperatingCashFlows.reduce(
    (sum, stat) => sum + stat.cashFromOperations!,
    0
  );

  const mostRecentStats = stats.at(-1);
  if (isNil(mostRecentStats)) return null;

  if (isNil(mostRecentStats.netDebt) || isNil(mostRecentStats.shares))
    return null;

  const enterpriseValue = getEnterpriseValue(
    mostRecentStats.shares,
    mostRecentStats.netDebt,
    stockPrice
  );

  if (isNil(enterpriseValue)) return null;
  return enterpriseValue / sumOfOCF;
}

function isDetailedProductionMissing(stat: ProductionStatistic) {
  return (
    isNil(stat.oilBBLD) &&
    isNil(stat.condensateBBLD) &&
    isNil(stat.naturalGasLiquidsBBLPerDay)
  );
}

/**
 * Weird function but is needed to flag if a companies production is missing detailed production
 * and should use the oilEquivalentBOED instead of the sum of the detailed production.
 * Suncor and Imperial oil are some examples of companies that do not report detailed production.
 * @param stats
 * @returns
 */
export function isMissingDetailedProduction(
  stats: CorporateQuarterlyStatistic[]
) {
  const rowsWithDetailedProduction = stats.filter(
    (stat) => !isDetailedProductionMissing(stat)
  ).length;

  const rowsWithOilEquivalentBOED = stats.filter(
    (stat) => isNumber(stat.oilEquivalentBOED) && stat.oilEquivalentBOED > 0
  ).length;

  return rowsWithOilEquivalentBOED > rowsWithDetailedProduction;
}

/**
 * Total production (boe/d) = oil (bbl/d) + condensate (bbl/d) + NGLs (bbl/d) + natural gas (boe/d).
 * @param stats
 * @returns
 */
export function getTotalBoeProduction(
  stat: ProductionStatistic & ExtendedProductionType
) {
  if (isDetailedProductionMissing(stat)) {
    return stat.oilEquivalentBOED ?? 0;
  }

  return (
    (stat.oilBBLD ? stat.oilBBLD : 0) +
    (stat.naturalGasBOEPerDay ? stat.naturalGasBOEPerDay : 0) +
    (stat.condensateBBLD ? stat.condensateBBLD : 0) +
    (stat.naturalGasLiquidsBBLPerDay ? stat.naturalGasLiquidsBBLPerDay : 0)
  );
}

/**
 * Generates a view of the quarterly statistic that includes calculated values.
 */
export function generateQuarterlyStatisticViews(
  stats: CorporateQuarterlyStatistic[]
): CorporateQuarterlyStatisticView[] {
  const views: CorporateQuarterlyStatisticView[] = [];

  orderBy(stats, ['year', 'quarter'], ['asc', 'asc']).forEach((stat, index) => {
    const netDebtOverCash =
      !isNumber(stat.netDebt) ||
      isNil(stat.netDebt) ||
      isNil(stat.cashFromOperations)
        ? null
        : round(stat.netDebt / stat.cashFromOperations, 2);

    const naturalGasMCF = isNil(stat.naturalGasMMCF)
      ? null
      : stat.naturalGasMMCF * 1000;

    const naturalGasBOEPerDay = isNil(naturalGasMCF)
      ? null
      : round(naturalGasMCF / 6);

    const nglAndCnd =
      isNil(stat.naturalGasLiquidsBBLPerDay) && isNil(stat.condensateBBLD)
        ? null
        : (stat.naturalGasLiquidsBBLPerDay ?? 0) + (stat.condensateBBLD ?? 0);

    const netProductionBOEPerDay =
      (stat.oilBBLD ?? 0) + (naturalGasBOEPerDay ?? 0) + (nglAndCnd ?? 0);

    const evPerBOEPerDay =
      !isNumber(stat.enterprise) || !isNumber(stat.oilEquivalentBOED)
        ? null
        : round(stat.enterprise / stat.oilEquivalentBOED);

    let evOverOCF = null;

    if (index > 2 && isNumber(stat.enterprise)) {
      const previousFourQuartersOfCashFromOperatons = stats
        .slice(index - 3, index + 1)
        .map((stat) => stat.cashFromOperations);
      if (
        every(previousFourQuartersOfCashFromOperatons, (val) => isNumber(val))
      ) {
        const sumOfCashFromOperations = sum(
          previousFourQuartersOfCashFromOperatons
        );
        evOverOCF = round(stat.enterprise / sumOfCashFromOperations, 1);
      }
    }

    const percentLiquidProduction =
      isNil(stat.liquidProductionsBBLD) || isNil(netProductionBOEPerDay)
        ? null
        : round((stat.liquidProductionsBBLD / netProductionBOEPerDay) * 100);

    const debtOverCashFlow =
      !isNumber(stat.netDebt) ||
      isNil(stat.netDebt) ||
      isNil(stat.cashFromOperations)
        ? null
        : round(stat.netDebt / stat.cashFromOperations, 2);

    views.push({
      ...stat,
      yearAndQuarter: parseInt(`${stat.year}${stat.quarter}`),
      formattedYearAndQuarter: `${stat.year}-Q${stat.quarter}`,
      positiveCapExp: stat.capitalExpenditure,
      naturalGasMCF,
      netDebtOverCash,
      netProductionBOEPerDay,
      nglAndCnd,
      naturalGasBOEPerDay,
      netDebtOverLTMOCF: null, // has to be calculated AFTER all other stats are calculated
      evPerBOEPerDay,
      evOverOCF, // has to be calculated AFTER all other stats are calculated
      percentLiquidProduction,
      debtOverCashFlow,
    });
  });

  // ensure order is correct
  const orderedStats = orderBy(views, ['yearAndQuarter'], ['asc']);
  // calculate LTM OCF (last twelve months of operating cash flow)
  for (let i = 3; i < orderedStats.length; i++) {
    const val = orderedStats[i];

    let sumOfCashFromOperations = 0;
    for (let j = i - 3; j < i; j++) {
      const cashFromOperations = orderedStats[j].cashFromOperations;
      if (isNumber(cashFromOperations)) {
        sumOfCashFromOperations += cashFromOperations;
      } else {
        sumOfCashFromOperations = 0;
        break;
      }
    }

    if (sumOfCashFromOperations > 0 && !isNil(val.netDebt)) {
      orderedStats[i].netDebtOverLTMOCF = round(
        val.netDebt / sumOfCashFromOperations,
        1
      );
    }

    sumOfCashFromOperations = 0;
  }
  return orderedStats;
}
