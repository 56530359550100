import { pnApiClient } from '@pn/services/api/pnApiClient';
import { useAuth0AuthenticationService } from '@pn/services/authentication/auth0/auth0AuthenticationService';
import { appSignalErrorLogger } from '@pn/services/loggers/appSignalErrorLogger';
import { mapboxPnMap } from '@pn/services/map/mapbox';
import { stackedNotificationService } from '@pn/services/notifications/stackedNotificationService';

export const useAuthenticationService = useAuth0AuthenticationService;
export const apiClient = pnApiClient;
export const errorLogger = appSignalErrorLogger;
export const notificationService = stackedNotificationService;
export const map = mapboxPnMap;
/* Convenience */
export const notify = notificationService.notify;
