import { Formation } from '@pn/core/domain/helper-data/formation.ts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  isFetching: boolean;
  isError: boolean;
  formations: Formation[];
};

const initialState: InitialState = {
  isFetching: false,
  isError: false,
  formations: [],
};

export const createFormationsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
      },
      receive: (state, action: PayloadAction<Formation[]>) => {
        state.formations = action.payload;
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
    },
  });

export const formationsSlice = createFormationsSlice('formations');
