import type { CanvasFeature } from '@pn/core/domain/drawing';
import { isAnnotationSource } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createAnnotationMapConfig,
  createDrawingMapConfig,
  createInitInternalState,
  type IWorkspaceItemMapper,
} from '@pn/core/domain/workspace';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import { apiProjectUserMapper } from '@pn/services/api/user/apiUserMapper';
import { isNil, omit } from 'lodash-es';
import type { ApiAnnotation, ApiAnnotationPayload } from './types';

export const apiAnnotationMapper = (): IWorkspaceItemMapper<
  ApiAnnotation,
  ApiAnnotationPayload
> => {
  return {
    toWorkspaceItem: (apiAnnotation) => {
      return {
        folder: getFolder(apiAnnotation),
        isTemporary: false,
        id: apiAnnotation.id,
        dataType: apiAnnotation.id,
        name: apiAnnotation.name,
        numberOfElements:
          apiAnnotation.definition.type === 'drawing'
            ? Object.keys(apiAnnotation.definition.features).length
            : apiAnnotation.definition.features.length,
        itemType:
          apiAnnotation.definition.type === 'drawing'
            ? 'drawing'
            : 'annotation',
        origin: 'pn',
        createdAt: apiAnnotation.created_at,
        updatedAt: apiAnnotation.updated_at,
        createdBy: !isNil(apiAnnotation.user)
          ? apiProjectUserMapper.toDomainProjectUser(apiAnnotation.user)
          : undefined,
        isGlobal: false,
        map:
          apiAnnotation.definition.type === 'drawing'
            ? createDrawingMapConfig({
                id: apiAnnotation.id,
                data: {
                  type: 'drawing',
                  features: apiAnnotation.definition.features.map(
                    (feature) =>
                      ({
                        ...feature,
                        itemId: apiAnnotation.id,
                        isVisible: true,
                      }) as CanvasFeature
                  ),
                },
              })
            : createAnnotationMapConfig({
                id: apiAnnotation.id,
                features: apiAnnotation.definition.features,
              }),
        dataSource: {
          type: 'none',
        },
        detailsSource: 'local',
        query: buildQuery({
          id: apiAnnotation.id,
          dataType: apiAnnotation.id,
        }),
        metadata: {
          isQueryArea: apiAnnotation.is_area,
        },
        ...createInitInternalState({ isVisualized: false }),
      };
    },
    toOriginalItem: (item) => {
      const layer = item.map.layers[0];

      if (layer.metadata?.type === 'drawing') {
        return {
          name: item.name,
          definition: {
            type: 'drawing',
            features: layer.metadata.features.map((feature) =>
              omit(feature, ['itemId', 'isVisible'])
            ),
          },
          is_area: item.metadata?.isQueryArea ?? false,
        };
      } else if (isAnnotationSource(layer.source)) {
        return {
          name: item.name,
          definition: {
            type: 'FeatureCollection',
            features: layer.source.data.features,
          },
          is_area: item.metadata?.isQueryArea ?? false,
        };
      } else {
        throw new Error('Invalid annotation item');
      }
    },
  };
};

function getFolder(apiAnnotation: ApiAnnotation): string {
  const userId = getCurrentUserId(); // HACK

  if (!isNil(userId) && apiAnnotation.user?.id === userId) {
    return 'Personal';
  } else {
    return 'Shared';
  }
}
