import { LayerType } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  getLayerId,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { isProduction } from '@pn/core/utils/env';
import { DEFAULT_COLOR, DEFAULT_SYMBOL_STYLE } from '@pn/services/styles';
import {
  abandonedGasIcon,
  abandonedInjectionIcon,
  abandonedOilIcon,
  abandonedSourceIcon,
  dryOrAbandonedIcon,
  gasIcon,
  injectionIcon,
  locationIcon,
  oilIcon,
  sourceIcon,
  suspendedGasIcon,
  suspendedInjectionIcon,
  suspendedOilIcon,
  suspendedSourceIcon,
} from '@pn/ui/icons/wellIcons';

export const wells: WorkspaceItem = {
  folder: 'Petro Ninja',
  isTemporary: false,
  id: 'wells',
  dataType: 'wells',
  itemType: 'layer',
  numberOfElements: undefined,
  createdBy: undefined,
  name: 'Wells',
  description: '885k wells in Canada',
  origin: 'pn',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  isGlobal: true,
  map: {
    layers: [
      {
        id: getLayerId('wells', 'wells', 0),
        name: 'Wells',
        type: LayerType.Icon,
        style: {
          ...DEFAULT_SYMBOL_STYLE,
          font: ['petroninja-font font'],
          offset: [0, 0.2],
        },
        source: {
          type: 'vector',
          url: isProduction()
            ? 'mapbox://sbilston.prod-wells-canada'
            : 'mapbox://sbilston.staging-wells-canada',
        },
        sourceLayer: 'bottoms',
      },
      {
        id: getLayerId('wells', 'wells', 1),
        name: 'Well Paths',
        type: LayerType.Line,
        style: {
          color: DEFAULT_COLOR,
          width: 1,
        },
        source: {
          type: 'vector',
          url: isProduction()
            ? 'mapbox://sbilston.prod-wells-canada'
            : 'mapbox://sbilston.staging-wells-canada',
        },
        sourceLayer: 'horizontals',
        sourceField: 'well_path_geometry',
      },
    ],
    filterProperty: {
      field: 'symbol',
      options: {
        i: {
          label: 'Oil',
          icon: oilIcon,
          value: true,
        },
        m: {
          label: 'Suspended Oil',
          icon: suspendedOilIcon,
          value: true,
        },
        c: {
          label: 'Abandoned Oil',
          icon: abandonedOilIcon,
          value: true,
        },
        o: {
          label: 'Gas',
          icon: gasIcon,
          value: true,
        },
        k: {
          label: 'Suspended Gas',
          icon: suspendedGasIcon,
          value: true,
        },
        a: {
          label: 'Abandoned Gas',
          icon: abandonedGasIcon,
          value: true,
        },
        g: {
          label: 'Injection',
          icon: injectionIcon,
          value: true,
        },
        l: {
          label: 'Suspended Injection',
          icon: suspendedInjectionIcon,
          value: true,
        },
        b: {
          label: 'Abandoned Injection',
          icon: abandonedInjectionIcon,
          value: true,
        },
        j: {
          label: 'Source',
          icon: sourceIcon,
          value: true,
        },
        n: {
          label: 'Suspended Source',
          icon: suspendedSourceIcon,
          value: true,
        },
        d: {
          label: 'Abandoned Source',
          icon: abandonedSourceIcon,
          value: true,
        },
        e: {
          label: 'Dry or Abandoned',
          icon: dryOrAbandonedIcon,
          value: true,
        },
        h: {
          label: 'Location',
          icon: locationIcon,
          value: true,
        },
      },
    },
  },
  dataSource: {
    type: 'api',
    source: 'parquet',
    url: 'v2/layers/wells',
    requiredMapDataFields: [
      'internal_id',
      'symbol',
      'geometry',
      'well_path_geometry',
    ],
  },
  detailsSource: 'api',
  query: buildQuery({
    id: 'wells',
    dataType: 'wells',
  }),
  ...createInitInternalState({ isVisualized: false }),
};
