import {
  Box,
  Button,
  CircularProgress,
  PaletteMode,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarCloseReason,
} from '@mui/material';
import { orange, red } from '@mui/material/colors';
import { Notification } from '@pn/core/services/notifications/ports';
import { isNil } from 'lodash-es';
import React from 'react';
import {
  Provider,
  TypedUseSelectorHook,
  createSelectorHook,
} from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  State,
  gracefullyRemoveNotification,
  notificationStore,
  removeNotification,
} from './stackedNotificationStore';

const useStyles = makeStyles()((theme) => ({
  snackbarContent: {
    paddingRight: 48,
  },
}));

const notificationStoreContext = React.createContext<any>(undefined);

type Props = {
  children: React.ReactNode;
};

export const StackedNotificationProvider = ({ children }: Props) => {
  return (
    <>
      <Provider store={notificationStore} context={notificationStoreContext}>
        <SnackbarManager maxStack={3} autoHideDuration={3000} />
      </Provider>
      {children}
    </>
  );
};

export const useNotificationSelector: TypedUseSelectorHook<State> =
  createSelectorHook(notificationStoreContext);

type SnackbarManagerProps = {
  maxStack: number;
  autoHideDuration: number;
};

const SnackbarManager = ({
  maxStack,
  autoHideDuration,
}: SnackbarManagerProps) => {
  const { classes, theme } = useStyles();
  const notifications = useNotificationSelector((state) => state.notifications);

  React.useEffect(() => {
    if (notifications.length > maxStack) {
      const firstToBeRemoved = notifications.filter(
        (notification) => !notification.isPersistent
      )[0];
      if (!isNil(firstToBeRemoved)) {
        removeNotification(firstToBeRemoved.id);
      }
    }
  }, [maxStack, notifications]);

  const handleClose = (
    notification: Notification,
    _reason: SnackbarCloseReason
  ) => {
    if (notification.isPersistent) return;

    gracefullyRemoveNotification(notification.id);
  };

  return (
    <>
      {notifications.map((notification, index) => (
        <Snackbar
          key={notification.id}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={notification.isOpen}
          autoHideDuration={
            notification.isPersistent || notification.type === 'error'
              ? null
              : autoHideDuration
          }
          message={<SnackbarMessage notification={notification} />}
          onClose={(_event, reason) => handleClose(notification, reason)}
          TransitionComponent={TransitionRight}
          ContentProps={{
            className: classes.snackbarContent,
            style: notificationTypeToStyle(theme.palette.mode)[
              notification.type
            ],
          }}
          style={{
            bottom: 24 + index * (48 + 16),
          }}
        />
      ))}
    </>
  );
};

function TransitionRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function SnackbarMessage({ notification }: { notification: Notification }) {
  return (
    <Box display="flex" alignItems="center">
      <Box>{notification.message ?? notification.jsx}</Box>
      {notification.showSpinner && (
        <Box
          position="relative"
          top="-1px"
          display="flex"
          alignItems="center"
          ml={2}
        >
          <CircularProgress size={18} color="inherit" />
        </Box>
      )}
      {!isNil(notification.onCancel) && (
        <Box ml={2}>
          <Button
            variant="text"
            color="warning"
            size="small"
            onClick={notification.onCancel}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
}

function notificationTypeToStyle(mode: PaletteMode) {
  return {
    default: {
      backgroundColor: mode === 'light' ? '#313131' : '#FBFBFB',
      color: mode === 'light' ? '#fff' : '#484848',
    },
    warning: {
      backgroundColor: mode === 'light' ? orange[700] : orange[500],
    },
    error: {
      backgroundColor: mode === 'light' ? red[700] : red[500],
    },
  };
}
