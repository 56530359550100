import { LinearProgress } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';
import { useAppStorage, useCurrentUserStorage } from '@pn/core/storage';
import { Auth0AuthProvider } from '@pn/services/authentication/auth0/Auth0AuthenticationProvider';
import { ErrorBoundaryProvider } from '@pn/services/loggers/AppSignalErrorBoundaryProvider';
import { StackedNotificationProvider } from '@pn/services/notifications/StackedNotificationProvider';
import { ReduxStoreProvider } from '@pn/services/redux/ReduxStoreProvider';
import { AnnotationProvider } from '@pn/ui/annotations/AnnotationProvider';
import { ConfirmationDialogStateProvider } from '@pn/ui/context-components/ConfirmationDialog';
import { ErrorPage } from '@pn/ui/error-page';
import { PetroNinjaThemeProvider } from '@pn/ui/theme/PetroNinjaThemeProvider';
import { WorkspaceItemPanelProvider } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useAutoInitializeApp } from 'src/application/useInitializeApp';
import history from 'src/history';
import { store } from 'src/storage/store';
import { Effects } from 'src/web-ui/effects';
import { StockChartRangeStateProvider } from './components/StockChartRange';
import { ActivityMapStateProvider } from './contexts/useActivityMap';
import { BOEIntelStateProvider } from './contexts/useBOEIntel';
import { BottomTableStateProvider } from './contexts/useBottomTable';
import { useAuthenticationService } from '@pn/maps/src/application/externalDependencies.ts';
import { useAutoTriggerAuth } from '@pn/core/operations/app';
import { isNil } from 'lodash-es';

const Dashboards = React.lazy(() => import('./Dashboards'));
const About = React.lazy(() => import('./pages/About'));
const Account = React.lazy(() => import('./pages/Account'));
const Plans = React.lazy(() => import('./pages/Plans'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const Terms = React.lazy(() => import('./pages/Terms'));
const OurData = React.lazy(() => import('./pages/our-data/OurData'));

if (!import.meta.env.VITE_APP_DATA_GRID_PRO_LICENSE_KEY) {
  throw new Error('VITE_APP_DATA_GRID_PRO_LICENSE_KEY not set');
}

LicenseInfo.setLicenseKey(
  import.meta.env.VITE_APP_DATA_GRID_PRO_LICENSE_KEY as string
);

export function App() {
  return (
    <ReduxStoreProvider store={store}>
      <PetroNinjaThemeProvider defaultPNTheme="BOE Dark">
        <Auth0AuthProvider
          history={history}
          useRefreshTokens={true}
          useLocalStorage={true}
        >
          <ErrorBoundaryProvider errorPage={ErrorPage}>
            <StackedNotificationProvider>
              <AppRoutesGateway />
            </StackedNotificationProvider>
          </ErrorBoundaryProvider>
        </Auth0AuthProvider>
      </PetroNinjaThemeProvider>
    </ReduxStoreProvider>
  );
}

function AppRoutesGateway() {
  const { isAppInitialized } = useAppStorage();

  useAutoInitializeApp(isAppInitialized);

  if (!isAppInitialized) {
    return <LinearProgress />;
  }

  return (
    <WorkspaceItemPanelProvider>
      <ConfirmationDialogStateProvider>
        <BOEIntelStateProvider>
          <StockChartRangeStateProvider>
            <BottomTableStateProvider>
              <ActivityMapStateProvider>
                <AnnotationProvider>
                  <AppRoutes />
                </AnnotationProvider>
              </ActivityMapStateProvider>
            </BottomTableStateProvider>
          </StockChartRangeStateProvider>
        </BOEIntelStateProvider>
      </ConfirmationDialogStateProvider>
    </WorkspaceItemPanelProvider>
  );
}

function AppRoutes() {
  const { user } = useCurrentUserStorage();
  const { isAuthenticating, isAuthenticated } = useAuthenticationService();
  const isAuthTriggered = useAutoTriggerAuth();

  const isAuthLoading =
    isAuthenticating || (isAuthenticated && isNil(user)) || !isAuthTriggered;

  return (
    <>
      <Effects />
      {isAuthLoading ? (
        <LinearProgress />
      ) : (
        <>
          {' '}
          <React.Suspense fallback={<LinearProgress />}>
            <Router history={history}>
              <Switch>
                <Route path="/terms" component={Terms} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/our-data" component={OurData} />
                <Route path="/account" component={Account} />
                <Route path="/plans" component={Plans} />
                <Route path="/dashboards" component={Dashboards} />
                <Route path="/" component={About} />
              </Switch>
            </Router>
          </React.Suspense>
        </>
      )}
    </>
  );
}
