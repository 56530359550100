import { LayerType } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';

export const _background: WorkspaceItem = {
  folder: 'Petro Ninja',
  isTemporary: false,
  id: '_background',
  dataType: '_background',
  numberOfElements: undefined,
  itemType: 'layer',
  name: 'Base Map',
  description: 'Background configuration',
  origin: 'pn',
  createdAt: new Date(0).toISOString(),
  updatedAt: new Date(0).toISOString(),
  createdBy: undefined,
  isGlobal: true,
  map: {
    layers: [
      {
        id: '',
        name: 'Base Map',
        type: LayerType.Mixed,
        style: { color: 'transparent' },
        source: { type: 'raster' },
        sourceLayer: undefined,
      },
    ],
  },
  dataSource: {
    type: 'none',
  },
  detailsSource: 'local',
  query: buildQuery({
    id: '_background',
    dataType: '_background',
  }),
  module: 'background',
  ...createInitInternalState({ isVisualized: true }),
};
