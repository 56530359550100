import { MonthlyLicensedProduction } from '@pn/core/domain/intel/monthly-licensed-production';
import { IApiMonthlyStatsMapper } from '@pn/core/mappers/intel';
import { isNil } from 'lodash-es';

export const apiMonthlyStatsMapper: IApiMonthlyStatsMapper = {
  toDomainMonthlyStats: (apiMonthlyStats) => {
    // TODO: fix the endpoint for this to not group by company
    if (isNil(apiMonthlyStats)) {
      throw new Error('apiIntel is not a valid object');
    }
    const initialMonthlyStats: MonthlyLicensedProduction[] = [];
    return apiMonthlyStats.reduce((monthlyStats, apiMonthlyStat) => {
      const companyMonthlyStats: MonthlyLicensedProduction[] =
        apiMonthlyStat.productions.map((apiProduction) => {
          const year = parseInt(apiProduction.date.split('-')[0]);
          const month = parseInt(apiProduction.date.split('-')[1]);

          const stat: MonthlyLicensedProduction = {
            id: apiMonthlyStat.company_id + year + month.toString(),
            companyId: apiMonthlyStat.company_id,
            year,
            month,
            oilBBLD: apiProduction.oil_bbl_d,
            naturalGasLiquidsBBLPerDay: apiProduction.ngl_bbl_d,
            naturalGasMMCF: apiProduction.natural_gas_mmcf_d,
            condensateBBLD: apiProduction.condensate_bbl_d,
            liquidProductionsBBLD: apiProduction.liquid_productions_bbl_d,
            oilEquivalentBOED: apiProduction.oil_equivalent_boe_d,
            provinces: apiProduction.provinces_included,
          };
          return stat;
        });
      return [...monthlyStats, ...companyMonthlyStats];
    }, initialMonthlyStats);
  },
  toDomainRecentMonthlyStats: (apiMonthlyStats) => {
    if (isNil(apiMonthlyStats)) {
      throw new Error('apiIntel is not a valid object');
    }
    return apiMonthlyStats.map((apiMonthlyStat) => {
      const year = parseInt(apiMonthlyStat.date.split('-')[0]);
      const month = parseInt(apiMonthlyStat.date.split('-')[1]);

      const stat: MonthlyLicensedProduction = {
        id: apiMonthlyStat.display_name,
        companyId: '',
        year,
        month,
        oilBBLD: apiMonthlyStat.oil_bbl_d,
        naturalGasLiquidsBBLPerDay: apiMonthlyStat.ngl_bbl_d,
        naturalGasMMCF: apiMonthlyStat.natural_gas_mmcf_d,
        condensateBBLD: apiMonthlyStat.condensate_bbl_d,
        liquidProductionsBBLD: apiMonthlyStat.liquid_productions_bbl_d,
        oilEquivalentBOED: apiMonthlyStat.oil_equivalent_boe_d,
        provinces: apiMonthlyStat.provinces_included,
      };
      return stat;
    });
  },
};
