import { MergerAndAquisition } from '@pn/core/domain/intel/intel-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  isError: false,
  mergersAndAcquisitions: [] as MergerAndAquisition[],
};

export const createMergersAndAcquisitionsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }: PayloadAction<MergerAndAquisition[]>) => {
        state.mergersAndAcquisitions = payload;
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
      },
    },
  });

export const mergersAndAcquisitionsSlice = createMergersAndAcquisitionsSlice(
  'mergersAndAcquisitions'
);
