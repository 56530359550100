/**
 * Emulates a delay.
 */
export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Enable to view debug logs from @pn/core.
 */
const DEBUG = false;

// const groups = new Set<string>();
const timers = new Map<string, number>();

export const log = {
  info: (message: string, ...args: any[]) => {
    if (DEBUG) {
      console.log(message, ...args);
    }
  },
  warn: (message: string, ...args: any[]) => {
    if (DEBUG) {
      console.warn(message, ...args);
    }
  },
  startGroup: (label: string) => {
    if (DEBUG) {
      // if (groups.has(label)) {
      //   log.warn(`Group already exists for ${label}`);
      // } else {
      //   groups.add(label);
      //   console.groupCollapsed(label);
      // }
      console.groupCollapsed(label);
    }
  },
  endGroup: (label: string) => {
    if (DEBUG) {
      // if (!groups.has(label)) {
      //   log.warn(`Group does not exist for ${label}`);
      // } else {
      //   groups.delete(label);
      //   console.groupEnd();
      // }
      console.groupEnd();
    }
  },
  time: (label: string, nonce: number = 0) => {
    if (DEBUG) {
      if (timers.has(label)) {
        log.warn(`Timer already exists for ${label}`);
      } else {
        timers.set(getLabelWithNonce(label, nonce), performance.now());
      }
    }
  },
  timeEnd: (label: string, nonce: number = 0) => {
    if (DEBUG) {
      const labelWithNonce = getLabelWithNonce(label, nonce);
      const start = timers.get(labelWithNonce);
      if (start) {
        timers.delete(labelWithNonce);
        const end = performance.now();
        const duration = end - start;
        log.info(`${label}: ${duration.toFixed(2)}ms`);
      } else {
        log.warn(`Timer does not exist for ${label}`);
      }
    }
  },
};

export function generateNonce() {
  return new Date().getTime();
}

function getLabelWithNonce(label: string, nonce: number) {
  return `${label}::${nonce}`;
}
