import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import type { IRealTimeStockActions, IRealTimeStockStorage } from './ports';
import { realTimeStockSlice } from './realTimeStockSlice';

export const useReduxRealTimeStockStorage = (): IRealTimeStockStorage => {
  const { name, getInitialState } = realTimeStockSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    stock: useSliceSelector((state) => state.stock),
  };
};
export const reduxRealTimeStockActions = (): IRealTimeStockActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = realTimeStockSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (company) => dispatch(actions.receive(company)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
  };
};
