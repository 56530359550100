import { dependencies } from '@pn/core/dependencies';
import { createProject } from '@pn/core/domain/project';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';
import type { IProjectsActions, IProjectsStorage } from './ports';
import { projectsSlice, type State } from './projectsSlice';

export const useReduxProjectsStorage = (): IProjectsStorage => {
  const { name, getInitialState } = projectsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    projects: useSliceSelector((state) => state.projects),
    selectedProject: useSliceSelector(selectSelectedProject),
  };
};

export const reduxProjectsActions = (): IProjectsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = projectsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    add: (projectOrProjects, upsert = true) =>
      dispatch(actions.add({ projectOrProjects, upsert })),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    create: (projectForm, user) =>
      dispatch(actions.create(createProject(projectForm, user))),
    update: (project) => dispatch(actions.update(project)),
    remove: (projectId) => dispatch(actions.remove(projectId)),
    removeStackProjects: () => dispatch(actions.removeStackProjects()),
    addItem: (projectId, item) =>
      dispatch(actions.addItem({ projectId, item })),
    removeItem: (projectId, item) =>
      dispatch(actions.removeItem({ projectId, item })),
    select: (projectId) => dispatch(actions.select(projectId)),
    unselect: () => dispatch(actions.select(undefined)),
  };
};

const selectSelectedProject = createSelector(
  [(state: State) => state.projects, (state: State) => state.selectedProjectId],
  (projects, selectedProjectId) =>
    projects.find((p) => p.id === selectedProjectId)
);
