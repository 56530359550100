import { toGeoPoint } from '@pn/core/domain/geography';
import { IntelPlans, UserPlans } from '@pn/core/domain/user';
import type {
  IInvitedUserMapper,
  IProjectUserMapper,
  IUserMapper,
} from '@pn/core/mappers/user';
import { generateTemporaryId } from '@pn/core/utils/id';
import { nullToUndefined } from '@pn/core/utils/logic';
import { apiUserEnterpriseMapper } from '@pn/services/api/enterprise/apiEnterpriseMapper';
import { isNil } from 'lodash-es';
import {
  type ApiEnterpriseInvitedUser,
  type ApiIncompleteUser,
  type ApiProjectUser,
  type ApiTargetUser,
  type ApiUser,
  isApiIncompleteUser,
  isApiUser,
} from './types';

export const apiUserMapper: IUserMapper<ApiUser | ApiIncompleteUser> = {
  toDomainUser: (apiUser) => {
    if (isApiUser(apiUser)) {
      return {
        id: apiUser.id,
        isInvited: false,
        email: apiUser.email,
        name: {
          firstName: apiUser.first_name ?? '',
          lastName: apiUser.last_name ?? '',
        },
        company: !isNil(apiUser.enterprise)
          ? apiUser.enterprise.name
          : (apiUser.company ?? ''),
        title: apiUser.title ?? '',
        userPlan: apiUser.user_plan,
        enterprise: !isNil(apiUser.enterprise)
          ? apiUserEnterpriseMapper.toDomainUserEnterprise(apiUser.enterprise)
          : undefined,
        stackCompanyId: nullToUndefined(apiUser.enterprise?.stack_company_id),
        authenticationType: nullToUndefined(apiUser.connection),
        acceptMail: apiUser.accept_mail ?? false,
        homeLocation:
          !isNil(apiUser.home_base_lat) && !isNil(apiUser.home_base_lng)
            ? toGeoPoint(apiUser.home_base_lat, apiUser.home_base_lng)
            : undefined,
        intelPlan: apiUser.intel_plan,
        createdAt: apiUser.created_at,
        stripeAccount: nullToUndefined(apiUser.stripe_account),
        isAdmin: apiUser.is_admin,
      };
    } else if (isApiIncompleteUser(apiUser)) {
      return {
        id: generateTemporaryId(),
        isInvited: !isNil(apiUser.enterprise),
        email: apiUser.email,
        name: {
          firstName: '',
          lastName: '',
        },
        company: !isNil(apiUser.enterprise) ? apiUser.enterprise.name : '',
        title: '',
        userPlan: apiUser.user_plan ?? UserPlans.Free,
        enterprise: !isNil(apiUser.enterprise)
          ? apiUserEnterpriseMapper.toDomainUserEnterprise(apiUser.enterprise)
          : undefined,
        stackCompanyId: nullToUndefined(apiUser.enterprise?.stack_company_id),
        authenticationType: undefined,
        acceptMail: true,
        homeLocation: undefined,
        intelPlan: IntelPlans.Starter,
        stripeAccount: undefined,
        isAdmin: false,
      };
    } else {
      throw new Error('Invalid API user');
    }
  },
  toTargetUser: (domainUser): ApiTargetUser => {
    // UserForm         -> User -> ApiUser
    // User['userPlan'] -> User -> ApiUser
    return {
      email: domainUser.email,
      first_name: domainUser.name.firstName,
      last_name: domainUser.name.lastName,
      company: domainUser.company,
      title: domainUser.title,
      accept_mail: domainUser.acceptMail,
      home_base_lat: domainUser.homeLocation?.lat,
      home_base_lng: domainUser.homeLocation?.lon,
      user_plan: domainUser.userPlan,
      enterprise_id: domainUser?.enterprise?.id,
      intel_plan: domainUser.intelPlan,
    };
  },
};

export const apiInvitedUserMapper: IInvitedUserMapper<ApiEnterpriseInvitedUser> =
  {
    toDomainInvitedUser: (apiInvitedUser) => {
      return {
        id: apiInvitedUser.id,
        email: apiInvitedUser.email,
        userPlan: apiInvitedUser.user_plan,
        enterprise: undefined,
        createdAt: apiInvitedUser.created_at,
        intelPlan: IntelPlans.Starter,
      };
    },
    toTargetInvitedUser: (domainInvitedUser) => {
      return {
        email: domainInvitedUser.email,
        user_plan: domainInvitedUser.userPlan,
      };
    },
  };

export const apiProjectUserMapper: IProjectUserMapper<ApiProjectUser> = {
  toDomainProjectUser: (apiProjectUser) => {
    return {
      id: apiProjectUser.id,
      email: apiProjectUser.email,
      name: {
        firstName: apiProjectUser.first_name ?? '',
        lastName: apiProjectUser.last_name ?? '',
      },
    };
  },
};
