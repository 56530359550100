import { channelsSlice } from '@pn/core/storage/channel/channelsSlice';
import { apiChannelMapper } from '@pn/services/api/channel/apiChannelMapper';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ReducerSync } from '.';

const { actions } = channelsSlice;

export const channels: ReducerSync<typeof actions> = {
  create: async (channel, dispatch) => {
    const response = await pnApiClient.request<{ id: string }>({
      method: 'POST',
      url: 'v2/channels',
      payload: apiChannelMapper.toTargetChannel(channel),
    });

    dispatch(actions._updateId({ currentId: channel.id, newId: response.id }));
  },
  update: ({ channelId, channelForm }) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v2/channels/${channelId}`,
      payload: {
        name: channelForm.name,
        user_ids: channelForm.userIds,
      },
    });
  },
  remove: (channelId) => {
    return pnApiClient.request({
      method: 'DELETE',
      url: `v2/channels/${channelId}`,
    });
  },
};
