import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { mergersAndAcquisitionsSlice } from './mergersAndAcquisitionsSlice';
import type {
  IMergersAndAcquisitionsActions,
  IMergersAndAcquisitionsStorage,
} from './ports';

export const useReduxMergersAndAcquisitionsStorage =
  (): IMergersAndAcquisitionsStorage => {
    const { name, getInitialState } = mergersAndAcquisitionsSlice;

    const useSliceSelector = useBuildSliceSelector(name, getInitialState());

    return {
      isFetching: useSliceSelector((state) => state.isFetching),
      isError: useSliceSelector((state) => state.isError),
      mergersAndAcquisitions: useSliceSelector(
        (state) => state.mergersAndAcquisitions
      ),
    };
  };

export const reduxMergersAndAcquisitionsActions =
  (): IMergersAndAcquisitionsActions => {
    const { dispatch, getState } = dependencies.store;
    const { actions, name } = mergersAndAcquisitionsSlice;

    if (isNil(getState()[name])) {
      throw new Error(`[${name}] reducer has not been injected yet`);
    }

    return {
      request: () => dispatch(actions.request()),
      receive: (mergersAndAcquisitions) =>
        dispatch(actions.receive(mergersAndAcquisitions)),
      error: () => dispatch(actions.error()),
      reset: () => dispatch(actions.reset()),
    };
  };
