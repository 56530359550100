import { Auth0Provider } from '@auth0/auth0-react';
import { ConfigurationError } from '@pn/core/errors';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isCordova } from '@pn/core/utils/env';
import { Auth0CallbackListener } from './Auth0CallbackListener';
import React from 'react';

const domain = import.meta.env.VITE_APP_AUTH0_DOMAIN;
const clientId = import.meta.env.VITE_APP_AUTH0_CLIENT_ID;
const audience = import.meta.env.VITE_APP_AUTH0_AUDIENCE;

type Props = {
  children: React.ReactNode;
  history: any;
  useRefreshTokens?: boolean;
  useLocalStorage?: boolean;
};

export const Auth0AuthProvider = ({
  children,
  history,
  useRefreshTokens = false,
  useLocalStorage = false,
}: Props) => {
  if (!domain || !clientId || !audience) {
    throw new ConfigurationError('Auth0 environment variables are not set');
  }

  /**
   * Goes back to last visited URL after login.
   */
  const onRedirectCallback = (appState?: { returnTo?: string }) => {
    history.push(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      useCookiesForTransactions={isEmbedded()} // https://github.com/auth0/auth0-spa-js/issues/593
      cacheLocation={isCordova() || useLocalStorage ? 'localstorage' : 'memory'}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={useRefreshTokens}
    >
      <>{children}</>
      <Auth0CallbackListener />
    </Auth0Provider>
  );
};
