import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import type { IIntelAlertsActions, IIntelAlertsStorage } from './ports';
import { intelAlertsSlice } from '@pn/core/storage/intel/intelAlertsSlice.ts';

export const useReduxIntelAlertsStorage = (): IIntelAlertsStorage => {
  const { name, getInitialState } = intelAlertsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    alerts: useSliceSelector((state) => state.alerts),
  };
};

export const reduxIntelAlertsActions = (): IIntelAlertsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = intelAlertsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (stats) => dispatch(actions.receive(stats)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    add: (stat) => dispatch(actions.add(stat)),
    update: (statId, alert) => dispatch(actions.update({ statId, alert })),
    remove: (statId) => dispatch(actions.remove(statId)),
    toggle: (statId) => dispatch(actions.toggle(statId)),
  };
};
