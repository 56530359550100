import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { formationsSlice } from './formationsSlice';
import type { IFormationsActions, IFormationsStorage } from './ports';

export const useReduxFormationsStorage = (): IFormationsStorage => {
  const { name, getInitialState } = formationsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    formations: useSliceSelector((state) => state.formations),
  };
};

export const reduxFormationsActions = (): IFormationsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = formationsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    receive: (formations) => dispatch(actions.receive(formations)),
    request: () => dispatch(actions.request()),
    error: () => dispatch(actions.error()),
  };
};
