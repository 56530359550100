import { Company } from '@pn/core/domain/intel/company';
import { CompanyKPI } from '@pn/core/domain/intel/intel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq, uniqBy } from 'lodash-es';

type InitialState = {
  isFetching: boolean;
  isError: boolean;
  kpis: CompanyKPI[];
  loadedCompanyIds: Company['id'][];
};

const initialState: InitialState = {
  isFetching: false,
  isError: false,
  kpis: [],
  loadedCompanyIds: [],
};

export const createCompanyKPIsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }: PayloadAction<CompanyKPI[]>) => {
        state.kpis = uniqBy([...state.kpis, ...payload], 'id');
        state.isFetching = false;
      },
      load: (state, { payload }: PayloadAction<Company['id'][]>) => {
        state.loadedCompanyIds = uniq([...state.loadedCompanyIds, ...payload]);
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
      },
    },
  });

export const companyKPIsSlice = createCompanyKPIsSlice('companyKPIs');
