import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { companyKPIsSlice } from './companyKPIsSlice';
import type { ICompanyKPIsActions, ICompanyKPIsStorage } from './ports';

export const useReduxCompanyKPIsStorage = (): ICompanyKPIsStorage => {
  const { name, getInitialState } = companyKPIsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    kpis: useSliceSelector((state) => state.kpis),
    loadedCompanyIds: useSliceSelector((state) => state.loadedCompanyIds),
  };
};

export const reduxCompanyKPIsActions = (): ICompanyKPIsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = companyKPIsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    receive: (kpis) => dispatch(actions.receive(kpis)),
    request: () => dispatch(actions.request()),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    load: (companyIds) => dispatch(actions.load(companyIds)),
  };
};
