import type {
  IEnterpriseMapper,
  IUserEnterpriseMapper,
} from '@pn/core/mappers/enterprise';
import { nullToUndefined, undefinedToNull } from '@pn/core/utils/logic';
import type { ApiEnterprise, ApiUserEnterprise } from './types';

export const apiEnterpriseMapper: IEnterpriseMapper<ApiEnterprise> = {
  toDomainEnterprise: (apiEnterprise) => {
    return {
      id: apiEnterprise.id,
      createdAt: apiEnterprise.created_at,
      name: apiEnterprise.name,
      logoUrl: apiEnterprise.logo,
      managedByActiveDirectory: apiEnterprise.is_managed_by_ad,
      authTenantId: nullToUndefined(apiEnterprise.auth_tenant_id),
      authenticationTypes: apiEnterprise.auth_connections,
      stackCompanyId: nullToUndefined(apiEnterprise.stack_company_id),
      stackCompanies: apiEnterprise.stack_companies?.map((company) => ({
        name: company.name,
        stackCompanyId: company.stack_company_id,
      })),
      channels: [], // for current enterprise, use `useChannelsStorage` instead
    };
  },
  toTargetEnterprise: (enterprise) => {
    return {
      id: enterprise.id,
      name: enterprise.name,
      logo: enterprise.logoUrl,
      created_at: enterprise.createdAt,
      is_managed_by_ad: enterprise.managedByActiveDirectory,
      auth_tenant_id: undefinedToNull(enterprise.authTenantId),
      auth_connections: enterprise.authenticationTypes,
      stack_company_id: undefinedToNull(enterprise.stackCompanyId),
      stack_companies: enterprise.stackCompanies.map((company) => ({
        name: company.name,
        stack_company_id: company.stackCompanyId,
      })),
    };
  },
};

export const apiUserEnterpriseMapper: IUserEnterpriseMapper<ApiUserEnterprise> =
  {
    toDomainUserEnterprise: (apiUserEnterprise) => {
      return {
        id: apiUserEnterprise.id,
        name: apiUserEnterprise.name,
        stackCompanyId: nullToUndefined(apiUserEnterprise.stack_company_id),
        stackCompanies: apiUserEnterprise.stack_companies.map((company) => ({
          name: company.name,
          stackCompanyId: company.stack_company_id,
        })),
      };
    },
  };
