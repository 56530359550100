import type { WorkspaceItem } from '@pn/core/domain/workspace';
import type { IProjectMapper } from '@pn/core/mappers/project';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import { getWorkspaceItemsByIds } from '@pn/core/storage/workspace/workspaceStorage';
import { nullToUndefined, undefinedToNull } from '@pn/core/utils/logic';
import { apiProjectUserMapper } from '@pn/services/api/user/apiUserMapper';
import { isNil } from 'lodash-es';
import type { ApiItem, ApiProject, ApiProjectPayload } from './types';

export const apiProjectMapper: IProjectMapper<ApiProject, ApiProjectPayload> = {
  toDomainProject: (apiProject) => {
    return {
      id: apiProject.id,
      name: apiProject.name,
      folder: getFolder(apiProject),
      createdAt: apiProject.created_at,
      updatedAt: apiProject.updated_at,
      createdBy: !isNil(apiProject.user)
        ? apiProjectUserMapper.toDomainProjectUser(apiProject.user)
        : undefined,
      channelId: nullToUndefined(apiProject.channel_id),
      workspaceItemIds: apiProject.item_ids,
      toggleAllEnabled: !apiProject.global && apiProject.name !== 'General',
      isGlobal: apiProject.global ?? false,
      origin: 'pn',
    };
  },
  toTargetProjectPayload: (project) => {
    // HACK the API requires knowing item types to decode the IDs
    const items = getWorkspaceItemsByIds(project.workspaceItemIds);

    return {
      name: project.name,
      channel_id: undefinedToNull(project.channelId),
      items: items.map(toApiItem),
    };
  },
};

export function toApiItem(item: WorkspaceItem): ApiItem {
  return {
    item_id: item.id,
    item_type: item.itemType,
    origin: item.origin,
  };
}

function getFolder(apiProject: ApiProject): string {
  const userId = getCurrentUserId();

  if (apiProject.global) {
    return 'Petro Ninja';
  } else if (!isNil(userId) && userId === apiProject.user?.id) {
    return 'Personal';
  } else {
    return 'Shared';
  }
}
