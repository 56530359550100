import { dependencies } from '@pn/core/dependencies';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { removeLayers } from '@pn/core/operations/workspace/layersProcessing';
import { useWorkspaceStorage, workspaceActions } from '@pn/core/storage';
import { isCordova } from '@pn/core/utils/env';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isNil } from 'lodash-es';
import React from 'react';

type Tab = 'Content' | 'Style';

type ContextType = {
  isWorkspaceItemPanelOpen: boolean;
  isDrawingPanelOpen: boolean;
  liveItemOpened: WorkspaceItem | undefined;
  tab: Tab;
  setTab: (tab: Tab) => void;
  openWorkspaceItemPanel: (params: { item: WorkspaceItem; tab?: Tab }) => void;
  closeWorkspaceItemPanel: (skipReset?: boolean) => void;
  resetCurrentItem: () => void;
};

const WorkspaceItemPanelContext = React.createContext({} as ContextType);
export const useWorkspaceItemPanel = () =>
  React.useContext(WorkspaceItemPanelContext);

type ContextProviderProps = {
  children: React.ReactNode;
};

export const WorkspaceItemPanelProvider = ({
  children,
}: ContextProviderProps) => {
  const { notificationService } = dependencies;
  const { smScreen } = useScreenSize();

  const { allWorkspaceItems } = useWorkspaceStorage();

  const [isOpen, setIsOpen] = React.useState(false);
  const [itemOpened, setItemOpened] = React.useState<
    WorkspaceItem | undefined
  >();
  const [tab, setTab] = React.useState<Tab>('Content');

  const liveItemOpened = React.useMemo(() => {
    return allWorkspaceItems.find(({ id }) => id === itemOpened?.id);
  }, [allWorkspaceItems, itemOpened?.id]);

  const isDrawingPanelOpen = isOpen && liveItemOpened?.itemType === 'drawing';

  const resetCurrentItem = () => {
    if (isNil(itemOpened) || isNil(liveItemOpened)) return;
    resetItem(itemOpened, liveItemOpened);
  };

  const openWorkspaceItemPanel = (params: {
    item: WorkspaceItem;
    tab?: Tab;
  }) => {
    const { item, tab } = params;

    if (item.itemType === 'drawing' && (smScreen || isCordova())) {
      notificationService.notify(
        'Drawing is not available on mobile devices',
        'warning'
      );
      return;
    }

    if (item.id !== itemOpened?.id) resetCurrentItem();

    setItemOpened(item);
    setIsOpen(true);
    if (tab) setTab(tab);
  };

  const closeWorkspaceItemPanel = () => {
    setIsOpen(false);
    setItemOpened(undefined);
  };

  return (
    <WorkspaceItemPanelContext.Provider
      value={{
        isWorkspaceItemPanelOpen: isOpen,
        isDrawingPanelOpen,
        liveItemOpened,
        tab,
        setTab,
        openWorkspaceItemPanel,
        closeWorkspaceItemPanel,
        resetCurrentItem,
      }}
    >
      {children}
    </WorkspaceItemPanelContext.Provider>
  );
};

/**
 * @param itemOpened - the state of the item when it was first opened
 * @param liveItem - the current state of the item
 */
function resetItem(itemOpened: WorkspaceItem, liveItem: WorkspaceItem): void {
  if (liveItem.isGlobal || !isNil(liveItem.module) || liveItem.isTemporary) {
    return;
  }

  /**
   * Remove layers that were added to the item while the panel was open.
   */
  const diffLayers = liveItem.map.layers.filter(
    (layer) => !itemOpened.map.layers.find(({ id }) => layer.id === id)
  );
  removeLayers(diffLayers);

  /**
   * Reset map configuration and a portion of the query.
   * Using `add` instead of `update` to prevent triggering sync-storage.
   */
  workspaceActions().add({
    ...itemOpened,
    requestedDataItem: liveItem.requestedDataItem,
    query: {
      ...liveItem.query,
      requestedIds: itemOpened.query.requestedIds,
    },
  });
  workspaceActions().revisualize(itemOpened.id);

  // if (itemOpened.itemType === 'drawing') {
  //   drawItem(itemOpened, drawingState);
  // }
}
