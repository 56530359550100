import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { companyMonthlyStatsSlice } from './companyMonthlyStatsSlice';
import type {
  IMonthlyStatisticsActions,
  IMonthlyStatisticsStorage,
} from './ports';

export const useReduxMonthlyStatsStorage = (): IMonthlyStatisticsStorage => {
  const { name, getInitialState } = companyMonthlyStatsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    stats: useSliceSelector((state) => state.stats),
    loadedCompanyIds: useSliceSelector((state) => state.loadedCompanyIds),
    recentStats: useSliceSelector((state) => state.recentStats),
  };
};

export const reduxMonthlyStatsActions = (): IMonthlyStatisticsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = companyMonthlyStatsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (stats) => dispatch(actions.receive(stats)),
    load: (companyIds) => dispatch(actions.load(companyIds)),
    requestRecent: () => dispatch(actions.requestRecent()),
    receiveRecent: (stats) => dispatch(actions.receiveRecent(stats)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
  };
};
