import { Company } from '@pn/core/domain/intel/company';
import { IIntelMonthlyStatsProvider } from '@pn/core/providers/intel/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ApiMonthlyStat } from './types';
import { apiMonthlyStatsMapper } from './apiMonthlyStatsMapper';

export const requestMonthlyStatsFromCompanyIds = async (
  companyIds: Company['id'][]
) => {
  const apiMonthlyStats = await pnApiClient.request<ApiMonthlyStat[]>({
    url: `v1/market_intel/licensed_production?company=${companyIds.join(',')}`,
  });

  return apiMonthlyStatsMapper.toDomainMonthlyStats(apiMonthlyStats);
};

export const requestRecentMonthlyStats = async (date?: string | null) => {
  return await pnApiClient.request<Record<string, unknown>[]>({
    url: `v1/market_intel/recent_licensed_stats`,
  });
};

export const apiMonthlyStatsProvider: IIntelMonthlyStatsProvider = {
  getMonthlyStatsByCompanyIds: requestMonthlyStatsFromCompanyIds,
  getRecentMonthlyStatsByDate: requestRecentMonthlyStats,
};
