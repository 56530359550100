import { currentUserSlice } from '@pn/core/storage/user/userSlice';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ReducerSync } from '.';

export const currentUser: ReducerSync<typeof currentUserSlice.actions> = {
  updateProfile: (userForm) => {
    const apiPayload: Record<string, unknown> = {
      accept_mail: userForm.acceptMail,
      email: userForm.email,
      first_name: userForm.firstName,
      last_name: userForm.lastName,
      company: userForm.company,
      title: userForm.title,
    };

    // const invite = localStorage.getItem('invite-string');
    // if (invite) {
    //   apiPayload.invite = invite;
    // }

    return pnApiClient.request({
      method: 'PUT',
      url: 'v2/user',
      payload: apiPayload,
    });
  },
  updateHomeLocation: (homeLocation) => {
    return pnApiClient.request({
      method: 'POST',
      url: 'v1/account/home_location',
      payload: {
        home_lat: homeLocation.lat,
        home_lng: homeLocation.lon,
      },
    });
  },
};
