import { projectsSlice } from '@pn/core/storage/project/projectsSlice';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import {
  apiProjectMapper,
  toApiItem,
} from '@pn/services/api/project/apiProjectMapper';
import { ReducerSync } from '.';

const { actions } = projectsSlice;

export const projects: ReducerSync<typeof projectsSlice.actions> = {
  create: async (project, dispatch) => {
    const { id } = await pnApiClient.request<{ id: string }>({
      method: 'POST',
      url: 'v2/projects',
      payload: apiProjectMapper.toTargetProjectPayload(project),
    });

    dispatch(actions.updateId({ currentId: project.id, newId: id }));
  },
  update: (project) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v2/projects/${project.id}`,
      payload: apiProjectMapper.toTargetProjectPayload(project),
    });
  },
  remove: (projectId) => {
    return pnApiClient.request({
      method: 'DELETE',
      url: `v2/projects/${projectId}`,
    });
  },
  addItem: ({ projectId, item }) => {
    return pnApiClient.request({
      method: 'POST',
      url: `v2/projects/${projectId}/add`,
      payload: toApiItem(item),
    });
  },
  removeItem: ({ projectId, item }) => {
    return pnApiClient.request({
      method: 'POST',
      url: `v2/projects/${projectId}/remove`,
      payload: toApiItem(item),
    });
  },
};
